import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faMagnifyingGlass,
  faUser,
  faPaw,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation, Link, createSearchParams } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import Connect from "../Connect/Connect";
import { Modal } from "react-bootstrap";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { AppContext } from "../../App";
import LogoVector from "../../Logo-vector.svg";
import { notify } from "../utils";
import { useGetFetch } from "../../core/Api";

function Navbar(props) {
  let context = useContext(AppContext);
  const [panierCounter, setPanierCounter] = context.panier;
  const [triggerPanierCount, setTriggerPanierCount] = context.panierChange;
  const [search, setSearch] = context.searchBar;
  const [navSearch, setNavSearch] = useState('');
  const location = useLocation().pathname;
  const [filler, setFiller] = useState(0);
  const [isSmall, setIsSmall] = useState(false);
  const [show, setShow] = context.loginmodal;
  const ref = useRef(null);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  }
  const navigate = useNavigate();
  const auth = useAuthUser();
  const isauth = useIsAuthenticated();

  const handleLogin = () => {
    if (isauth()) {
      navigate("/userspace");
    } else {
      handleShow();
    }
  };

  const handleClickSearch = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    e.preventDefault();
    setSearch(navSearch);
    if (location !== "/search") {
      navigate({
        pathname:
          "/search"
      });
    }
  };

  useGetFetch(process.env.REACT_APP_SERVER + "products/getclientcart", { idclient: isauth() ? auth().user.id : 0 }, (response) => {
    if (response.cart) {
      setPanierCounter(response.cart.length);
    }
  }, [triggerPanierCount]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    };
    if (ref.current) {
      setFiller(ref.current.clientHeight + 50);
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  if (["/", "/login", "/signup"].includes(location)) {
    return null;
  } else {
    return (
      <>
        <header ref={ref} style={{ position: "fixed", zIndex: "1", left: "50%", transform: "translateX(-50%)" }} className={isSmall ? "small" : ""}>
          <button className={`logo ${isSmall ? "small" : ""}`} onClick={() => navigate("/home")}>
            <img
              src={LogoVector}
              alt="fondpaw"
              className="image-logo"
            />
          </button>
          <p className={`slogan ${isSmall ? "small" : ""}`}>
            <FontAwesomeIcon icon={faPaw} /> On s'en lèche les moustaches
            <FontAwesomeIcon icon={faPaw} />
          </p>
          <input type="checkbox" id="nav_check" hidden />
          <nav>
            <div className="logo">
              <img
                src={LogoVector}
                alt="fondpaw"
                className="image-logo"
              />
              <input type="checkbox" id="nav_check" hidden />
            </div>
            <ul className="icon-section">
              <li>
                <form className="search-box" onSubmit={handleClickSearch}>
                  <button
                    type="submit"
                    className={`btn-search ${isSmall ? "small" : ""}`}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type="text"
                    value={navSearch}
                    className={`input-search ${isSmall ? "small" : ""}`}
                    onChange={(e) => setNavSearch(e.target.value)}
                    placeholder="Recherche..."
                  />
                </form>
              </li>

              <li>
                <a id="login" className={isauth() ? "navbar-icon connected" : "navbar-icon"} onClick={handleLogin}>
                  <FontAwesomeIcon icon={faUser} />
                </a>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Connexion</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Connect handleClose={handleClose} />
                  </Modal.Body>
                </Modal>
              </li>
              <li>
                {isauth() &&
                  <Link
                    className="navbar-icon panier-icon"
                    data-count={panierCounter}
                    to="/shoppingcart"
                  >
                    <FontAwesomeIcon icon={faCartShopping} />
                  </Link>
                }
                {!isauth() &&
                  <Link
                    className="navbar-icon panier-icon"
                    data-count={panierCounter}
                    onClick={() => {handleShow(); notify('error', 'Veuillez vous connecter pour accéder au panier.')}}
                  >
                    <FontAwesomeIcon icon={faCartShopping} />
                  </Link>
                }
              </li>
            </ul>
            <ul className="text-section">
              <li>
                <Link to="/list" >Produits</Link>
              </li>
              <li>
                <Link to="/chat" >Chats</Link>
              </li>
              <li>
                <Link to="/rongeur" >Rongeurs</Link>
              </li>
              <li>
                <Link to="/boxsurprise" >Box Surprise</Link>
              </li>
              <li>
                <Link to="/list" >Soldes</Link>
              </li>
              <li>
                <a href="">Livraison</a>
              </li>
            </ul>
          </nav>
          <label htmlFor="nav_check" className="hamburger">
            <div></div>
            <div></div>
            <div></div>
          </label>
        </header>
        <div style={{ height: filler + 'px' }}></div>

      </>
    );
  }
}

Navbar.propTypes = {};
Navbar.defaultProps = {};
export default Navbar;
