import './App.css';
import { Navigate, Route, Routes } from "react-router-dom"
import Home from './containers/Home/Home';
import Garde from './containers/Home/Garde';
import ProductPage from './containers/ProductPage/ProductPage';
import Userspace from './containers/Userspace/Userspace';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import SignUp from './containers/Login/Signup';
import React, { createContext, useState } from 'react';
import Categories from './containers/Categories/Categories';
import Boxsurprise from './containers/Boxsurprise/Boxsurprise';
import BoxsurpriseContent from './containers/Boxsurprise/BoxsurpriseContent';
import ProductSearch from './containers/ProductSearch/ProductSearch';
import ShoppingCart from './containers/ShoppingCart/ShoppingCart';
import Search from './containers/Search/Search';
import { useIsAuthenticated } from 'react-auth-kit';
import { useLocation } from "react-router-dom";
import OrderTracking from './containers/OrderTracking/OrderTracking';
import { PaymentConfirm } from './components/Payment/PaymentConfirm';
import { ToastContainer } from 'react-toastify';
const GlobalContext = createContext();

function ProtectedRoute({ userIsAuth, children }) {
  if (!userIsAuth) {
    return <Navigate to="/home" replace />;
  }
  return children;
}

function App() {
  const [panierCounter, setPanierCounter] = useState(0);
  const [search, setSearch] = useState('');
  const [triggerPanierCount, setTriggerPanierCount] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const isAuth = useIsAuthenticated();
  const location = useLocation().pathname;

  let contextGlobal = { 
    panier: [panierCounter, setPanierCounter], 
    searchBar: [search, setSearch], 
    panierChange : [triggerPanierCount, setTriggerPanierCount],
    loginmodal : [showLogin, setShowLogin],
    rootdns: process.env.REACT_APP_DNS_ROOT 
  };

  return (
    <div className='App'>
      <div className='container-root'>
        <GlobalContext.Provider value={contextGlobal}>
          {["/", "/home", "/login", "/signup"].includes(location.pathname) ? null : <Navbar />}
          <div className='wrapper-content'>
            <Routes>
              <Route path='/' element={<Garde />} />
              <Route path='/signup' element={<SignUp />} />
              <Route path='/boxsurprise' element={<Boxsurprise />} />
              <Route path='/boxsurprise/open/chat' element={<BoxsurpriseContent />} />
              <Route path='/boxsurprise/open/rongeur' element={<BoxsurpriseContent />} />
              <Route path='/home' element={<Home />} />
              <Route path='/productpage' element={<ProductPage />} />
              <Route path='/userspace' element={<Userspace />} />
              <Route path='/chat' element={<Categories />} />
              <Route path='/rongeur' element={<Categories />} />
              <Route path='/list' element={<ProductSearch />} />
              <Route path='/shoppingcart' element={
                <ProtectedRoute userIsAuth={isAuth()}>
                  <ShoppingCart />
                </ProtectedRoute>
              } />
              <Route path='/orders' element={
                <ProtectedRoute userIsAuth={isAuth()}>
                  <OrderTracking />
                </ProtectedRoute>
              } />
              <Route path='/paymentconfirm' element={
                <ProtectedRoute userIsAuth={isAuth()}>
                  <PaymentConfirm />
                </ProtectedRoute>
              } />
              <Route path='/search' element={<Search />}/>
            </Routes>
            <ToastContainer />
          </div>
          <Footer />
        </GlobalContext.Provider>
      </div>
    </div>
  );
}

export default App;
export const AppContext = GlobalContext;