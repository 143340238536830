import Carousel from 'react-bootstrap/Carousel';
import './CarouselHome.css'
import React, { useEffect, useState } from 'react';

function ControlledCarousel() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    const indicators = document.querySelectorAll('.carousel-indicators button');
    indicators.forEach(indicator => {
      indicator.style.backgroundImage = `url(${process.env.REACT_APP_DNS_ROOT}/SVGs/patoune.svg)`;
    });
  }, []);

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />} nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}>
      <Carousel.Item>
        <img
          className="d-block w-100" draggable="false"
          src= { process.env.REACT_APP_SERVER + "images/produit_12.jpg" }
          alt="First slide"
        />
        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img 
          className="d-block w-100" draggable="false"
          src= { process.env.REACT_APP_SERVER + "images/produit_14.jpg" }
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100" draggable="false"
          src= { process.env.REACT_APP_SERVER + "images/produit_15.jpg" }
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;