// UserSaved.js
import React from "react";
// Css relatif au fichier
import "./UserSaved.css";


function UserSaved() {
  return (
    <div className="user-block">
    <h2>Vos produits Enregistrés :</h2>
    <ul>
      <li>
        <p className="title-info"><a href="/productpage?mode=add" >Ajouter un produit</a></p>
      </li>
    </ul>
  </div>
  );
}

export default UserSaved;
