import React, { useState, useEffect, useContext } from "react";
import "./Userspace.css";
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";

import UserInfo from './UserInfo';
import UserSaved from './UserSaved';
import UserHistory from './UserHistory';
import UserFidelity from './UserFidelity';
import UserPromo from './UserPromo';
import UserComm from './UserComm';
import { AppContext } from "../../App";
import { useGetFetch } from "../../core/Api";

function Userspace(props) {
  const context = useContext(AppContext);
  const [triggerPanierCount, setTriggerPanierCount] = context.panierChange;
  const signOut = useSignOut();
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState("user-info");
  const [clientInfo, setClientInfo] = useState(null);
  const isAuth = useIsAuthenticated();
  const auth = useAuthUser();

  useGetFetch(
    process.env.REACT_APP_SERVER + "products/getclientinfo", 
    { idclient: isAuth() ? auth().user.id : 0 },
    (response) => {
      if (response.clientInfo && response.clientInfo.length > 0) {
        setClientInfo(response.clientInfo[0]);
      }
    }
  );

  const handleLogout = () => {
    signOut();
    setTriggerPanierCount(state => !state);
    navigate("/");
  };

  const renderActiveComponent = () => {
    switch(activeComponent) {
      case "user-info":
        return <UserInfo clientInfo={clientInfo} />;
      case "user-saved":
        return <UserSaved clientInfo={clientInfo} />;
      case "user-history":
        return <UserHistory clientInfo={clientInfo} />;
      case "user-fidelity":
        return <UserFidelity clientInfo={clientInfo} />;
      case "user-promo":
        return <UserPromo clientInfo={clientInfo} />;
      case "user-comm":
        return <UserComm/>;
      default:
        return <UserInfo clientInfo={clientInfo} />;
    }
  };

  return (
    <div className="ProductPage">
      <main>
        <div className="title-userspace">
          <h1 style={{ fontSize: "50px" }} className="espace_utilisateur">
            Mon compte Miustache
          </h1>
          <h2>Bonjour {clientInfo ? clientInfo.Nom : ''} !</h2>
        </div>
        <div className="great_container">
          <ul className="selection-buttons">
            <li>
              <button onClick={() => setActiveComponent("user-info")}>Mes Informations</button>
            </li>
            <li>
              <button onClick={() => setActiveComponent("user-saved")}>Mes Produits Enregistrés</button>
            </li>
            <li>
              <button onClick={() => setActiveComponent("user-history")}>Mes commandes</button>
            </li>
            <li>
              <button onClick={() => setActiveComponent("user-fidelity")}>Fidélité</button>
            </li>
            <li>
              <button onClick={() => setActiveComponent("user-promo")}>Codes Promos</button>
            </li>
            <li>
              <button onClick={() => setActiveComponent("user-comm")}>Mes Avis</button>
            </li>
            <li>
              <button className="userbox-disconnect" onClick={handleLogout}>Déconnexion</button>
            </li>

          </ul>
          <div className="container_space_two">
            <div className="usercontent">
              {clientInfo && renderActiveComponent()}
            </div>
          </div>
        </div>
        <div className="great_container_responsive">
          <Navbar defaultExpanded className=".navbar-expand" expand="lg" variant="Light">
            <Container>
              <Navbar.Brand className="user-brand">Compte</Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <NavDropdown title="Mes Informations" id="collasible-nav-dropdown">
                    <div className="user-block">
                      {clientInfo && <UserInfo clientInfo={clientInfo} />}
                    </div>
                  </NavDropdown>
                  <NavDropdown title="Mes Produits Enregistrés" id="collasible-nav-dropdown">
                    <div className="user-block">
                      {clientInfo && <UserSaved clientInfo={clientInfo} />}
                    </div>
                  </NavDropdown>
                  <NavDropdown title="Mes commandes" id="collasible-nav-dropdown">
                    <div className="user-block">
                      {clientInfo && <UserHistory clientInfo={clientInfo} />}
                    </div>
                  </NavDropdown>
                  <NavDropdown title="Fidélité" id="collasible-nav-dropdown">
                    <div className="user-block">
                      {clientInfo && <UserFidelity clientInfo={clientInfo} />}
                    </div>
                  </NavDropdown>
                  <NavDropdown title="Codes Promos" id="collasible-nav-dropdown">
                    <div className="user-block">
                      {clientInfo && <UserPromo clientInfo={clientInfo} />}
                    </div>
                  </NavDropdown>
                  <NavDropdown title="Mes Avis" id="collasible-nav-dropdown">
                    <div className="user-block">
                      {clientInfo && <UserComm clientInfo={clientInfo} />}
                    </div>
                  </NavDropdown>
                  <Nav.Link className="user-disconnect" onClick={handleLogout}>Déconnexion</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </main>
    </div>
  );
}

export default Userspace;