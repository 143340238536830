import React, { useState, useEffect } from "react";
import ProductForm from '../../components/ProductForm/ProductForm';
import ProductFormMobile from '../../components/ProductForm/ProductFormMobile';
import ProductInfo from '../../components/Product/ProductInfo/ProductInfo';
import CarouselProducts from '../../components/CarouselProducts/CarouselProducts';
import './BoxsurpriseContent.css';
import { createSearchParams, useNavigate, useLocation } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import Commentaire from '../../components/Commentaire/Commentaire';
import { useGetFetch } from "../../core/Api";


function BoxsurpriseContent(props) {

  const [productsFoodChat, setProductsFoodChat] = useState ([]);
  const [productsFoodRongeur, setProductsFoodRongeur] = useState ([]);
  const [productChat, setProductChat] = useState([]);
  const [productsSuggestionsChat, setProductsSuggestionsChat] = useState([]);
  const [productRongeur, setProductRongeur] = useState([]);
  const [productsSuggestionsRongeur, setProductsSuggestionsRongeur] = useState([]);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation().pathname;
  const [commentairerongeur, setCommentaireRongeur] = useState([]);
  const [commentairechat, setCommentaireChat] = useState([]);

  useGetFetch(process.env.REACT_APP_SERVER + "products/singleproduct", { reference: "12" }, (datas) => { setProductChat(datas.product) });
  useGetFetch(process.env.REACT_APP_SERVER + "products/list", {sort: 'nom_asc', category:'Friandise', animal: 'Chat'}, (data) => { setProductsFoodChat(data.products) });
  useGetFetch(process.env.REACT_APP_SERVER + "products/suggestions", { reference: "12"  }, (datas) => { setProductsSuggestionsChat(datas.product) });
  useGetFetch(process.env.REACT_APP_SERVER + "products/commentaire", { reference: "12"  }, (datas) => { setCommentaireChat(datas.product) });

  useGetFetch(process.env.REACT_APP_SERVER + "products/singleproduct", { reference: "16" }, (datas) => { setProductRongeur(datas.product) });
  useGetFetch(process.env.REACT_APP_SERVER + "products/list", {sort: 'nom_asc', category:'Friandise', animal: 'Rongeur'}, (data) => { setProductsFoodRongeur(data.products) });
  useGetFetch(process.env.REACT_APP_SERVER + "products/suggestions", { reference: "16"  }, (datas) => { setProductsSuggestionsRongeur(datas.product) });
  useGetFetch(process.env.REACT_APP_SERVER + "products/commentaire", { reference: "16"  }, (datas) => { setCommentaireRongeur(datas.product) });


  useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 1100);
      };
  
      window.addEventListener("resize", handleResize);
  
      handleResize();
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleSeeMore = (animal, type) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate({
            pathname: "/list",
            search: createSearchParams({
                sort: "nom_asc",
                category: "Tous",
                animal: animal,
            }).toString(),
        });
    };

    const handleSeeNextRongeur = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth",
      });
      navigate("/boxsurprise/open/rongeur");
  };


  const handleSeeNextChat = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    navigate("/boxsurprise/open/chat");
};


  if (location === "/boxsurprise/open/chat") {
    return (
      <main>
        <div>
            {isMobile ? (
                <div className="ProductPage">
                  <ProductFormMobile product={productChat} setProduct={setProductChat} />
                  <Commentaire commentaire={commentairechat} setCommentaire={setCommentaireChat}/>
                </div>
              ) : (
                <div className="ProductPage">
                  <ProductForm product={productChat} setProduct={setProductChat} />
                  <Commentaire commentaire={commentairechat} setCommentaire={setCommentaireChat}/>
                </div>
            )}
          <button className='box_week_a' onClick={() => { handleSeeNextRongeur() }}>Vous avez un rongeur ?</button>
          <div>
            <h2>Que peut-il y avoir dans votre Box surprise ?</h2>
            <div className="productbox">
            {productsFoodChat.length > 0 && <CarouselProducts products={productsFoodChat} />}
            </div>
            </div>
          </div>
          <div className="contentvideo">
            <img
              src="/images/fondpaw.svg"
              alt="fondpaw"
              className="patoune_video"
            />
            <h2>Unboxing d'une Box Surprise</h2>
            <iframe className='video' width="560" height="315" src="https://www.youtube.com/embed/SJP5ZWGWbtA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div className="container-categories">
            <h2>Ces Produits Pourraient vous intéresser</h2>
            <div className="masterbox">
                {productsSuggestionsChat.map((product) =>
                    <ProductInfo product={product} />
                )}
            </div>
            <div className="voir_plus" data-aos="zoom-in" onClick={() => { handleSeeMore('Friandise', 'Tous') }}>
              <h2>
                Voir Plus  ➜
              </h2>
            </div>
          </div>

        </main>
    );

  } else {
    
    return (
      <main>
        <div>
            {isMobile ? (
                <div className="ProductPage">
                  <ProductFormMobile product={productRongeur} setProduct={setProductRongeur} />
                  <Commentaire commentaire={commentairerongeur} setCommentaire={setCommentaireRongeur}/>
                </div>
              ) : (
                <div className="ProductPage">
                  <ProductForm product={productRongeur} setProduct={setProductRongeur} />
                  <Commentaire commentaire={commentairerongeur} setCommentaire={setCommentaireRongeur}/>
                </div>
            )}
          <button className='box_week_a' onClick={() => { handleSeeNextChat() }}>Vous avez un chat ?</button>
          <div>
            <h2>Que peut-il y avoir dans votre Box surprise ?</h2>
            <div className="productbox">
            {productsFoodRongeur.length > 0 && <CarouselProducts products={productsFoodRongeur} />}
            </div>
            </div>
          </div>
          <div className="contentvideo">
            <img
              src="/images/fondpaw.svg"
              alt="fondpaw"
              className="patoune_video"
            />
            <h2>Unboxing d'une Box Surprise</h2>
            <iframe className='video' width="560" height="315" src="https://www.youtube.com/embed/SJP5ZWGWbtA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div className="container-categories">
            <h2>Ces Produits Pourraient vous intéresser</h2>
            <div className="masterbox">
                {productsSuggestionsRongeur.map((product) =>
                    <ProductInfo product={product} />
                )}
            </div>
            <div className="voir_plus" data-aos="zoom-in" onClick={() => { handleSeeMore('Friandise', 'Tous') }}>
              <h2>
                Voir Plus  ➜
              </h2>
            </div>
          </div>

        </main>
    );
  }             
}


BoxsurpriseContent.propTypes = {};

BoxsurpriseContent.defaultProps = {};

export default BoxsurpriseContent;