// UserPromo.js
import React from "react";

function UserPromo() {
  return (
    <div className="user-block">
      <h2>Codes Promos</h2>
      <ul>
        <li>
          <p className="title-info">Entrer un code promotionel</p>
        </li>
        <li>
          <p className="title-info">Codes actifs</p>
          <p className="actual-info">[Codes actif] : #% de réduction sur votre prochaine commande.</p>
        </li>
      </ul>
    </div>
  );
}

export default UserPromo;
