// UserInfo.js
import React from "react";

function UserInfo({ clientInfo }) {
  return (
    <div className="user-block">
      <h2 id="user-info">Mes informations</h2>
      <ul>
        <li>
          <p className="title-info">Mes informations personnelles</p>
          <p className="actual-info">
            {`Nom : ${clientInfo.Nom}`}
          </p>
          <p className="actual-info">
            {`Prenom : ${clientInfo.Prenom}`}
          </p>
          <p className="actual-info">
            {`Date de Naissance : ${clientInfo.DateDeNaissance}`}
          </p>
          <p className="actual-info">
            {`Email : ${clientInfo.EmailClient}`}
          </p>
          <p className="actual-info">
            {`Type Animaux : ${clientInfo.TypeAnimaux}`}
          </p>
          <p className="actual-info">
            {`Type Client : ${clientInfo.TypeClient}`}
          </p>
        </li>
        <li>
          <p className="title-info">Mes adresses</p>
          <p className="actual-info">{clientInfo.AdresseFracturation}</p>
        </li>
        <li>
          <p className="title-info">Abonnement à la Newsletter</p>
          <p className="actual-info">{clientInfo.Newsletter ? 'Oui' : 'Non'}</p>
        </li>
      </ul>
    </div>
  );
}

export default UserInfo;
