import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement
} from "@stripe/react-stripe-js";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";

const CheckoutForm = ({products, clientSecret, total}) => {
  const stripe = useStripe();
  const elements = useElements();
  const auth = useAuthUser();
  const isauth = useIsAuthenticated();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const jsonProductsRefQuantity = JSON.stringify(products.map((p) => { return { ref: p.Reference, quantity: p.quantity } }));
    const encodedJsonPRQ = encodeURIComponent(jsonProductsRefQuantity);
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
          return_url: process.env.REACT_APP_DNS_ROOT + "/paymentconfirm?mode=stripe&total=" + total + "&productsrefquantity=" + encodedJsonPRQ,
      },
    });
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
    </form>
  );
}

export default CheckoutForm;