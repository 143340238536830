import React, { useEffect } from "react";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { Link } from "react-router-dom";

function paymentApproved(total, refApi, user, mode, productsRefQuantity) {
    fetch(process.env.REACT_APP_SERVER + "products/approuve-order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cartdel: { action: 'removeall', idclient: user.id },
        orderInfos: { idclient: user.id, totAmount: total, delivAdress: user.address, refApi: refApi, mode: mode, productsRefQuantity: productsRefQuantity }
      }),
    });
    fetch(process.env.REACT_APP_SERVER + "mailer/send-confirm-email", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: user.firstname, price: total, dest: user.email }),
    });
};

function PaymentConfirm() {
    const auth = useAuthUser();
    const isauth = useIsAuthenticated();
    const getQueryParam = (paramName) => {
        return new URLSearchParams(window.location.search).get(paramName);
    };
    const total = getQueryParam('total');
    let refApi = getQueryParam('refApi');
    const mode = getQueryParam('mode');
    const productsrefquantity = getQueryParam('productsrefquantity');
    if (mode == 'stripe') {
        refApi = getQueryParam('payment_intent');
    }

    useEffect(() => {
        if (mode) {
            let processComplete = localStorage.getItem(mode + 'ProcessingComplete');
            if (total && refApi && productsrefquantity && processComplete && processComplete == 'true' && isauth()) {
                localStorage.setItem(mode + 'ProcessingComplete', 'false');
                paymentApproved(total, refApi, auth().user, mode, JSON.parse(decodeURIComponent(productsrefquantity)));
            }
        }
    }, [total, refApi, mode, productsrefquantity]);

    let content = <div></div>;
    if (mode) {
        content =
            <div style={{ height: '60vh', marginTop: '100px' }}>
                Commande approuvée, vous allez recevoir un mail<br/>
                Accédez au suivi de vos commandes <Link to="/orders" >ici</Link><br/>
                Ou continuez vos achats <Link to="/list" >ici</Link>
            </div>
    }

    return (
        <>
            {content}
        </>
    );
}

PaymentConfirm.propTypes = {};

PaymentConfirm.defaultProps = {};

export { PaymentConfirm, paymentApproved };