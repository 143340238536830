import React, { useState, useEffect } from "react";
import "./CarouselProducts.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaw } from "@fortawesome/free-solid-svg-icons";
import ProductInfo from "../Product/ProductInfo/ProductInfo";

function CarouselProducts({ products }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const itemsPerPage = 4;
  const totalSlides = Math.ceil(products.length / itemsPerPage);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const displayProducts = products.slice(
    currentSlide * itemsPerPage,
    (currentSlide + 1) * itemsPerPage
  );

  useEffect(() => {
    const interval = setInterval(handleNextSlide, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (products.length === 0) {
    return <div className="carousel-container2">Aucun produit disponible.</div>;
  }

  return (
    <div className="carousel-container2">
      <div className="carousel-wrapper2">
        <div className="carousel-items2">
          {displayProducts.map((product) => (
            <ProductInfo key={product.Reference} product={product} />
          ))}
        </div>
      </div>
      <div className="carousel-navigation2">
        <button
          className={`carousel-prev-button2 ${currentSlide === 0 ? 'disabled2' : ''}`}
          onClick={handlePrevSlide}
          disabled={currentSlide === 0}
        >
          <FontAwesomeIcon icon={faPaw} className="carousel-paw-icon" />
        </button>
        <div className="carousel-page-number">
          {currentSlide + 1} / {totalSlides}
        </div>
        <button
          className={`carousel-next-button2 ${currentSlide === totalSlides - 1 ? 'disabled2' : ''}`}
          onClick={handleNextSlide}
          disabled={currentSlide === totalSlides - 1}
        >
          <FontAwesomeIcon icon={faPaw} className="carousel-paw-icon" />
        </button>
      </div>
    </div>
  );
}

export default CarouselProducts;