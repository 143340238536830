import React, { useMemo, useState } from "react";
import ".//ProductSearch.css";
import ProductInfo from '../../components/Product/ProductInfo/ProductInfo';
import Filter from '../../components/Filter/Filter';
import { useLocation } from "react-router-dom";
import { useGetFetch } from "../../core/Api";

function ProductSearch() {
    const [products, setProducts] = useState([]);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [tri, setTri] = useState(params.get("sort") ? params.get("sort") : "nom_asc");
    const [animal, setAnimal] = useState(params.get("animal") ? params.get("animal") : '');
    const [category, setCategory] = useState(params.get("category") ? params.get("category") : '');
    const [filters, setFilters] = useState({'animal' : animal, 'category' : category, 'sort': tri});

    useGetFetch(process.env.REACT_APP_SERVER + "products/list", filters, (data) => {setProducts(data.products)}, [filters]);
    
    return (
        <>
        <Filter setFilters={setFilters} filters={filters} setAnimal={setAnimal}  setCategory={setCategory} setTri={setTri} tri={tri} animal={animal} category={category}/>
        <div className="container-categories-search">
            <div className="masterbox-search">
            {products.map((product) => (
                <ProductInfo product={product} />
            ))}
            </div>
        </div>
        </>
  );
}

ProductSearch.propTypes = {};

ProductSearch.defaultProps = {};

export default ProductSearch;
