// UserFidelity.js
import React from "react";

function UserFidelity() {
  return (
    <div className="user-block">
      <h2>Fidélité</h2>
      <ul>
        <li>
          <p className="actual-info">Nombre de points : </p>
          <p className="actual-info"><br></br> Tous les 150 points, vous recevez automatiquement un coupons de 5 €</p>
        </li>
      </ul>
    </div>
  );
}

export default UserFidelity;
