import React, { useContext, useState, useEffect } from 'react';
import './ProductForm.css';
import { postFetch } from '../../core/Api';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from '../../App';
import CarouselMobile from '../Carousel/CarouselMobile';


function TextAreaField(props) {
  if (props.mode == "readonly") {
    return (<div className={props.class}><p>{props.value}</p></div>);
  } else if (props.mode == "edit") {
    return (<textarea onChange={props.handleChange} className={props.class} name={props.name} value={props.value} />)
  }
}

function Field(props) {
  if (props.mode == "readonly") {
    return (<div className={props.class}>{props.title ? props.title + ' : ' : ''}{props.value}</div>);
  } else if (props.mode == "edit") {
    return (<div className={props.class}>{props.title ? props.title + ' : ' : ''}<input onChange={props.handleChange} name={props.name} value={props.value} /></div>)
  }
}

function CustomDatePicker(props) {
  if (props.mode == "readonly") {
    return (
      <div className='date_peremption'>
        {props.title}
        <DatePicker style={{ border: "none" }} selected={props.date} />
      </div>
    );
  } else if (props.mode == "edit") {
    return (
      <div className='date_peremption'>
        {props.title}
        <DatePicker selected={props.date} onChange={(date) => { props.setProduct(prevState => ({ ...prevState, ['DateDePeremption']: date })); }} />
      </div>
    )
  }
}

function ProductForm(props) {
  const [panierCounter, setPanierCounter] = useContext(AppContext).panier;
  const [quantity, setQuantity] = useState(1); // état local pour la quantité
  const [uploadedfiles, setUploadedFiles] = useState([]); // état local pour la quantité
  const [queryParameters] = useSearchParams() // Paramètres dans l'url
  const navigate = useNavigate();
  const auth = useAuthUser();
  const isauth = useIsAuthenticated();
  let idclient = isauth() ? auth().user.id : 0;

  const addToCart = (productid, idclient, quantity) => {
    let formData = { idclient: idclient, idproduct: productid, action: "add" };
    postFetch(process.env.REACT_APP_SERVER + "products/setcart", formData, () => { setPanierCounter(panierCounter + quantity) })
  }

  const mode = queryParameters.get("mode") ? queryParameters.get("mode") : 'readonly';
  let product = props.product;


  const decreaseQuantity = () => {
    if (quantity > 1) { // vérifie que la quantité ne soit pas déjà à 1
      setQuantity(quantity - 1);
    }
  };

  const handleAddToCart = () => {
    for (let i = 0; i < quantity - 1; i++) {
      addToCart(props.product.Reference, idclient);
    }
    addToCart(props.product.Reference, idclient, quantity);
  }

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    props.setProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData()
    for (const file of uploadedfiles) {
      formData.append('files', file, file.name);
    }
  
    formData.append('product', JSON.stringify(product))
    const requestOptions = {
      method: 'POST',
      body: formData
    };
    try {
      fetch(process.env.REACT_APP_SERVER + "products/update", requestOptions)
        .then(async function (res) {
          const status = res.status;
          const response = await res.json();
          response.status = status;
          navigate({
            pathname: "/productpage",
            search: createSearchParams({
                reference: queryParameters.get("reference")
            }).toString()
        });
        });
    } catch (error) {
      console.log(error)
    }
  };

  const handleFileUpload = (e) => {
    setUploadedFiles(e.target.files);
  };

  return (
    <form className="form-product" onSubmit={handleSubmit}>
      <main>
        <div className="container-product">

          <div className="title">
            <h1 className="titre">{product.NomProduit}</h1>
          </div>
            <div className="rate">
              <input type="radio" id="star5" name="rate" value="5" />
              <label htmlFor="star5" title="text">
                5 stars
              </label>
              <input type="radio" id="star4" name="rate" value="4" />
              <label htmlFor="star4" title="text">
                4 stars
              </label>
              <input type="radio" id="star3" name="rate" value="3" />
              <label htmlFor="star3" title="text">
                3 stars
              </label>
              <input type="radio" id="star2" name="rate" value="2" />
              <label htmlFor="star2" title="text">
                2 stars
              </label>
              <input type="radio" id="star1" name="rate" value="1" />
              <label htmlFor="star1" title="text">
                1 star
              </label>
            </div>
            <div className="prix-et-date">
              <Field title="Marque" name="Marque" class="marque" handleChange={handleChange} value={product.Marque} mode={mode} />
              <CustomDatePicker date={product.DateDePeremption ? new Date(product.DateDePeremption) : new Date()} title="Date de péremption :" setProduct={props.setProduct} mode={mode} />
            </div>
            <CarouselMobile handleFileUpload={handleFileUpload} product={product} mode={mode} />
            <div className="product-form-prix-et-date">
              <Field title="Prix" name="Prix" class="prix" handleChange={handleChange} value={product.Prix} mode={mode} />
              <h1>En stock</h1>
            </div>
            {mode == "readonly" ? (
              <div>
                <div className="quantite_et_option">
                  <div className="quantite">
                    Quantité :
                    <button onClick={decreaseQuantity}><FontAwesomeIcon icon={faChevronDown} /></button>
                    {quantity}
                    <button onClick={() => setQuantity(quantity + 1)}><FontAwesomeIcon icon={faChevronUp} /></button>
                  </div>
                  <div className="option">Option : {product.Option} {product.TypeArticle}</div>
                </div>
                <a className="panier-product" onClick={handleAddToCart}>Ajouter au panier</a>
                <div className="acheter-product">Acheter maintenant</div>
                <div className="wish-product"><a href=''>Ajouter à la liste de souhait</a></div>
              </div>
            ) :
              <button type='submit' className='btn btn-success mt-5'>Enregistrer</button>
            }
            <TextAreaField class="description_produit" name="Description" handleChange={handleChange} value={`Détails du produit : ${product.Description}`} mode={mode} />
          </div>
      </main>
    </form>
  );
}


ProductForm.propTypes = {};

ProductForm.defaultProps = {};

export default ProductForm;