import React, { useContext, useState, useEffect } from 'react';
import './ProductForm.css';
import Carousel from '../Carousel/Carousel';
import { postFetch } from '../../core/Api';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from '../../App';
import Badge from '../Badge/Badge';
import { notify } from '../utils';
import { toast } from 'react-toastify';


function TextAreaField(props) {
  if (props.mode == "readonly") {
    return (<div className={props.class}><p>{props.value}</p></div>);
  } else if (props.mode == "edit") {
    return (<textarea onChange={props.handleChange} className={props.class} name={props.name} value={props.value} />)
  }
}

function Field(props) {
  if (props.mode == "readonly") {
    return (<div className={props.class}>{props.title ? props.title + ' : ' : ''}{props.value}</div>);
  } else if (props.mode == "edit") {
    return (<div className={props.class}>{props.title ? props.title + ' : ' : ''}<input onChange={props.handleChange} name={props.name} value={props.value} /></div>)
  }
}

function SelectField(props) {
  if (props.mode == "readonly") {
    return (<div className={props.class}>{props.title ? props.title + ' : ' : ''}{props.value}</div>);
  } else if (props.mode == "edit") {
    return (
      <div className={props.class}>{props.title ? props.title + ' : ' : ''}
        <select onChange={props.handleChange} name={props.name}>
          {props.choices.map((choice) =>
            <option value={choice} selected={choice === props.value ? "selected" : null}>{choice}</option>
          )}
        </select>
      </div>
    )
  }
}

function CustomDatePicker(props) {
  if (props.mode == "readonly") {
    return (
      <div className='date_peremption'>
        {props.title}
        <DatePicker dateFormat="dd/MM/yyyy" style={{ border: "none" }} selected={props.date} />
      </div>
    );
  } else if (props.mode == "edit") {
    return (
      <div className='date_peremption'>
        {props.title}
        <DatePicker dateFormat="dd/MM/yyyy" selected={props.date} onChange={(date) => { props.setProduct(prevState => ({ ...prevState, ['DateDePeremption']: date })); }} />
      </div>
    )
  }
}

function ProductForm(props) {
  const context = useContext(AppContext);
  const [panierCounter, setPanierCounter] = context.panier;
  const [quantity, setQuantity] = useState(1); // état local pour la quantité
  const [uploadedfiles, setUploadedFiles] = useState(new Set());
  const [imagesDelete, setImagesDelete] = useState([]);
  const [mode, setMode] = useState(props.mode);
  const [queryParameters] = useSearchParams() // Paramètres dans l'url
  const navigate = useNavigate();
  const auth = useAuthUser();
  const isauth = useIsAuthenticated();
  let idclient = isauth() ? auth().user.id : 0;
  const role = isauth() ? auth().user.role : "";

  const addToCart = (productid, idclient, quantity) => {
    let formData = { idclient: idclient, idproduct: productid, action: "add" };
    postFetch(process.env.REACT_APP_SERVER + "products/setcart", formData, () => { setPanierCounter(panierCounter + quantity) })
  }

  let product = props.product;

  const decreaseQuantity = () => {
    if (quantity > 1) { // vérifie que la quantité ne soit pas déjà à 1
      setQuantity(quantity - 1);
    }
  };

  const handleAddToCart = () => {
    for (let i = 0; i < quantity - 1; i++) {
      addToCart(props.product.Reference, idclient);
    }
    addToCart(props.product.Reference, idclient, quantity);
  }

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    props.setProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSeeMore = (setMode) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setMode('edit');
  };

  const validProductUpdate = function () {
    let missingFields = '';
    for (const cle in product) {
      if (!['Reference', 'TypeArticle', 'DateMiseEnLigne', 'TypeDeProduit', 'note', 'nbrnotes', 'LienVideo', 'Option', 'Image'].includes(cle)
        && !product[cle]) {
        missingFields += ' ' + cle;
      }
    }
    if (missingFields) {
      missingFields = 'Missing fields :' + missingFields;
      notify('error', missingFields);
      return false;
    }
    return true;
  }

  const handleSubmit = (e, setMode) => {
    e.preventDefault();
    let isvalid = validProductUpdate();
    if (isvalid) {
      const formData = new FormData();
      console.log(uploadedfiles)
      for (const file of uploadedfiles) {
        formData.append('files', file, file.name);
      }
      formData.append('filesdelete', JSON.stringify(imagesDelete));
      formData.append('product', JSON.stringify(product))
      const requestOptions = {
        method: 'POST',
        body: formData
      };
      try {
        fetch(process.env.REACT_APP_SERVER + "products/update", requestOptions)
          .then(async function (res) {
            const status = res.status;
            const response = await res.json();
            response.status = status;
            if (response.action == 'insert') {
              window.location.assign("/productpage?reference=" + response.reference);
              notify('success', 'Produit ajouté !');
            } else {
              notify('success', 'Produit mis à jours !');
              setMode('readonly');
            }
          });
      } catch (error) {
        notify('error', error);
      }
    }
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const newUploadedFiles = new Set(uploadedfiles);
    for (let i = 0; i < files.length; i++) {
      newUploadedFiles.add(files[i]);
    }
    setUploadedFiles(newUploadedFiles);
  }

  return (
    <form className="form-product" onSubmit={(e) => handleSubmit(e, setMode)}>
      <main>
        <div className="container-product">
          <Carousel handleFileUpload={handleFileUpload} setImagesDelete={setImagesDelete} imagesDelete={imagesDelete} product={product} mode={mode} />
          <div className="right_column">
            <div className="product-form-rate rate">
              <input type="radio" id="star5" name="rate" value="5" />
              <label htmlFor="star5" title="text">
                5 stars
              </label>
              <input type="radio" id="star4" name="rate" value="4" />
              <label htmlFor="star4" title="text">
                4 stars
              </label>
              <input type="radio" id="star3" name="rate" value="3" />
              <label htmlFor="star3" title="text">
                3 stars
              </label>
              <input type="radio" id="star2" name="rate" value="2" />
              <label htmlFor="star2" title="text">
                2 stars
              </label>
              <input type="radio" id="star1" name="rate" value="1" />
              <label htmlFor="star1" title="text">
                1 star
              </label>
            </div>
            <div className="title" style={{ backgroundImage: `url(${context.rootdns + '/SVGs/Cadre2.svg'})` }}>
              <h1 className="titre">
                <Field name="NomProduit" class="nomProduit" handleChange={handleChange} value={product.NomProduit} mode={mode} />
              </h1>
            </div>
            <div className="product-form-prix-et-date">
              <Field title="Marque" name="Marque" class="marque" handleChange={handleChange} value={product.Marque} mode={mode} />
              <div className='stock-container'>
                <p className='product-form-stock'>En stock</p>
                <Badge value={product.Quantite} />
              </div>
            </div>
            <div className="prix-et-date">
              <Field title="Prix" name="Prix" class="product-form-prix" handleChange={handleChange} value={product.Prix} mode={mode} />
              <CustomDatePicker date={product.DateDePeremption ? new Date(product.DateDePeremption) : new Date()} title="Date de péremption :" setProduct={props.setProduct} mode={mode} />
            </div>
            <TextAreaField class="description_produit" name="Description" handleChange={handleChange} value={product.Description} mode={mode} />
            {mode == "edit" ? (
              <>
                <SelectField title="Type de produit" class="type_produit" name="TypeDeProduit" handleChange={handleChange} value={product.TypeDeProduit} mode={mode} choices={['Friandise', 'Accessoire']} /><br />
                <SelectField title="Theme" class="theme" name="Theme" handleChange={handleChange} value={product.Theme} mode={mode} choices={['Chat', 'Rongeur']} />
              </>
            ) : <></>}
            {mode == "readonly" ? (
              <div>
                <div className="quantite_et_option">
                  <div className="quantite">
                    Quantité :
                    <button onClick={decreaseQuantity}><FontAwesomeIcon icon={faChevronDown} /></button>
                    <div className='form-quantite-number'>{quantity}</div>
                    <button onClick={() => setQuantity(quantity + 1)}><FontAwesomeIcon icon={faChevronUp} /></button>
                  </div>
                  <div className="option">Option : {product.Option} {product.TypeArticle}</div>
                </div>
                <a className="form-panier-product" onClick={handleAddToCart}>Ajouter au panier</a>
                <div className="form-acheter-product">Acheter maintenant</div>
                {role == 'admin' &&
                  <div className='product-mod' onClick={() => { handleSeeMore(setMode) }}>Modifier</div>
                }
                <div className="wish-product"><a href=''>Ajouter à la liste de souhait</a></div>
              </div>
            ) :
              <button type='submit' className='btn btn-success mt-5'>Enregistrer</button>
            }
          </div>
        </div>
      </main>
    </form>
  );

}


ProductForm.propTypes = {};

ProductForm.defaultProps = {};

export default ProductForm;