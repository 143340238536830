import React from 'react';
import './Footer.css';
import { useLocation } from 'react-router-dom'; 
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';


function Footer() {
  if (['/', '/login', '/signup'].includes(useLocation().pathname)) {
    return null;
  } else {
    return(
    <footer>
      <div className="footer-link">

        <ul className='footer-link-flex'>
          <li>
            <a href="">Expedition</a>
          </li>
          <li>
            <a href="">Retour du produit</a>
          </li>
          <li>
            <a href="">Politique de confidentialité</a>
          </li>
          <li>
            <a href="">Conditions d'utilisation</a>
          </li>
          <li>
            <a href="">Nous Contacter</a>
          </li>
        </ul>

    <ul className='footer-brand'>
      <li>
        <a className='brand-hover' href=''><FontAwesomeIcon icon={faYoutube} /></a>
      </li>
      <li>
        <a className='brand-hover' href=''><FontAwesomeIcon icon={faInstagram} /></a>
      </li>
      <li >
        <a className='brand-hover' href=''><FontAwesomeIcon icon={faTiktok} /></a>
      </li>
    </ul>


        <p className="copyright">
          Copyright &copy; Inseparables Studios SAS, 2023-2024, All rights reserved

        </p>
      </div>
    </footer>);
  }
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
