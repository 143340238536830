import React, { } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const notify = function (type, message) {
    let options = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
    };
    switch (type) {
        case 'error':
            toast.error(message, options);
            break;
        case 'success':
            toast.success(message, options);
            break;
        default:
            toast(message, options);
            break;
    }
}

export { notify };