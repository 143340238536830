// UserComm.js
import React, { useState } from "react";
// Css relatif au fichier
import "./UserComm.css";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useGetFetch } from "../../core/Api";

function UserComm({ }) {
  const [commentaires, setCommentaires] = useState([]);
  const isAuth = useIsAuthenticated();
  const auth = useAuthUser();

  useGetFetch(
    process.env.REACT_APP_SERVER + "products/getcommentaire", 
    { idclient: isAuth() ? auth().user.id : 0 },
    (response) => {
      if (response.commentaires && response.commentaires.length > 0) {
        setCommentaires(response.commentaires);
      }
    }
  );


  return (
    <div className="user-block user-comm-content">
      {/* Ajoutez ici le contenu pour "Mes Avis" */
        <div>
          <h2>Vos avis :</h2>
          {commentaires.map((comment, index) => (
          <div className='user-comm-author-info'>
            <div className='user-comm-pfp'><FontAwesomeIcon icon={faUser} /></div>
            <div className='user-comm-author-rate'>
              <div className="commentaire-rate rate">
                <input type="radio" id="star5" name="rate" value="5" />
                <label htmlFor="star5" title="text">
                  5 stars
                </label>
                <input type="radio" id="star4" name="rate" value="4" />
                <label htmlFor="star4" title="text">
                  4 stars
                </label>
                <input type="radio" id="star3" name="rate" value="3" />
                <label htmlFor="star3" title="text">
                  3 stars
                </label>
                <input type="radio" id="star2" name="rate" value="2" />
                <label htmlFor="star2" title="text">
                  2 stars
                </label>
                <input type="radio" id="star1" name="rate" value="1" />
                <label htmlFor="star1" title="text">
                  1 star
                </label>
              </div>
              <p className="comm-user-content">{comment.contenu}</p>
            </div>
        </div>
        ))}
        </div>
      }
    </div>
  );
}

export default UserComm;
