import React, { useContext, useEffect, useState } from "react";
import ProductInfo from '../../components/Product/ProductInfo/ProductInfo';
import { useSearchParams } from "react-router-dom";
import { AppContext } from "../../App";
import { useGetFetch } from "../../core/Api";

function ProductSearch() {
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useContext(AppContext).searchBar;
    
    useGetFetch(process.env.REACT_APP_SERVER + "products/search", { search }, (data) => {
        setProducts(data.products);
    }, [search]);


    return (
        <>
            <h1>Résultat de recherche pour : {search}</h1>
            <div className="container-categories">
                <div className="masterbox">
                    {products.map((product) => (
                        <ProductInfo key={product.IdProduit} product={product} />
                    ))}
                </div>
            </div>
        </>
    );
}

export default ProductSearch;