import { useEffect } from "react";

const getFetch = (url, jsonDatas, callback) => {
  const dataFetch = async () => {
    const data = await (
      await fetch(url + '?' + new URLSearchParams(jsonDatas))
    ).json();
    callback(data);
  };
  dataFetch();
}

const useGetFetch = (url, jsonDatas, callback, dependecies = []) => {
  useEffect(() => {
    getFetch(url, jsonDatas, callback);
  }, dependecies);
}

const postFetch = (url, jsonDatas, callback) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(jsonDatas)
  };
  fetch(url, requestOptions)
    .then(async function (res) {
      const status = res.status;
      const response = await res.json();
      response.status = status;
      callback(response);
    });
};

export {useGetFetch, postFetch, getFetch}