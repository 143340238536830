import React, { useEffect } from 'react';
import Product from '../../components/Product/Product';
import CarouselHome from '../../components/CarouselHome/CarouselHome';


function Home(props) {
    return (
        <div className="home">
            <CarouselHome />
            <br/>
            <br/>
            <Product />
        </div>
    );
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;