// UserHistory.js
import React from "react";

function UserHistory() {
  return (
    <div className="user-block">
      <h2>Mes commandes</h2>
      <ul>
        <li>
          <p className="title-info"><a href="/orders" >Dernières commandes</a></p>
        </li>
        <li>
          <p className="title-info">Mes moyens de paiement</p>
          <div className="user-card-add">
            <div><p>+</p></div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default UserHistory;
