import React, { useContext, useEffect, useState } from "react";
import ".//ShoppingCart.css";
import { postFetch, useGetFetch } from '../../core/Api';
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcMastercard, faCcVisa, faCcAmex, faCcPaypal } from '@fortawesome/free-brands-svg-icons';
import { faAngleDown, faChevronDown, faChevronUp, faLongArrowAltLeft, faLongArrowAltRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";
import Dropdown from 'react-bootstrap/Dropdown';
import Payment from "../../components/Payment/Payment";
import { Oval } from "react-loader-spinner";

function CartElement(props) {
    let product = props.product;
    const context = useContext(AppContext);
    const [panierCounter, setPanierCounter] = context.panier;
    const auth = useAuthUser();
    const isauth = useIsAuthenticated();

    const setQuantity = function (action, reference, quantity, deletenumber = "one") {
        postFetch(process.env.REACT_APP_SERVER + "products/setcart", { idproduct: reference, action: action, idclient: isauth() ? auth().user.id : 0, delete: deletenumber }, () => {
            if ((quantity === 1 || deletenumber == "all") && action == "remove") {
                setPanierCounter(count => count - 1);
            }
            props.setTriggerCartChange(state => !state);
        });
    }

    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex justify-content-between cart-product-left">
                    <div className="d-flex flex-row align-items-center">
                        <div className="cart-product-image">
                            <img draggable="false"
                                src={process.env.REACT_APP_SERVER + product.Image.split(';')[0]}
                                className="rounded-3"
                                alt="Shopping item"
                                style={{ width: '200px' }}
                            />
                        </div>
                        <div className="ms-3 cart-product-info">
                            <h5>{product.NomProduit}</h5>
                            <div className="rate">
                                <input type="radio" id="star5" name="rate" value="5" />
                                <label htmlFor="star5" title="text">5 stars</label>
                                <input type="radio" id="star4" name="rate" value="4" />
                                <label htmlFor="star4" title="text">4 stars</label>
                                <input type="radio" id="star3" name="rate" value="3" />
                                <label htmlFor="star3" title="text">3 stars</label>
                                <input type="radio" id="star2" name="rate" value="2" />
                                <label htmlFor="star2" title="text">2 stars</label>
                                <input type="radio" id="star1" name="rate" value="1" />
                                <label htmlFor="star1" title="text">1 star</label>
                            </div>
                            <p className="small mb-0">
                                {product.Description.length > 50
                                    ? product.Description.substring(0, 100) + "..."
                                    : product.Description}
                            </p>
                        </div>
                    </div>
                    <div className="cart-price-quantity-cross" >
                        <div style={{ width: 'auto' }}>
                            <h5 className="fw-normal mb-0">
                                <div className="quantite" style={{ display: "flex", alignItems: "center" }}>
                                    <button style={{ backgroundColor: "white", margin: "0 10px 0 0" }} onClick={() => setQuantity('remove', product.Reference, product.quantity)}>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </button>
                                    <span>{product.quantity}</span>
                                    <button style={{ backgroundColor: "white", margin: "0 0 0 10px" }} onClick={() => setQuantity('add', product.Reference, product.quantity)}>
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </button>
                                </div>
                            </h5>
                        </div>
                        <div style={{ width: '80px' }}>
                            <p className="cart-price">{product.Prix.toFixed(2)} €</p>
                        </div>
                        <a href="#!" className="cart-cross" style={{ color: '#363636' }} onClick={() => setQuantity('remove', product.Reference, product.quantity, 'all')}>
                            <FontAwesomeIcon icon={faXmark} />
                        </a>
                    </div>
                </div>
            </div>
            <p className="sixtyminutes">*Les articles suivants seront réservés pendant 60 minutes*</p>

        </div>

    )
}

function ShoppingCart(props) {
    const context = useContext(AppContext);
    const [products, setProducts] = useState([]);
    const [pay, setPay] = useState(false);
    const [triggerCartChange, setTriggerCartChange] = useState(false);
    const [methodPay, setMethodPay] = useState('');
    const [partnerState, setPartnerState] = useState({ status: false });
    const auth = useAuthUser();
    const isauth = useIsAuthenticated();

    useGetFetch(process.env.REACT_APP_SERVER + "products/getclientcart", { idclient: isauth() ? auth().user.id : 0 }, (data) => {
        setProducts(data.cart);
    }, [triggerCartChange]);

    useEffect(() => {
        let methodePayParam = new URLSearchParams(window.location.search).get("mode");

        if (methodePayParam) {
            setPay(true);
            setMethodPay(methodePayParam);
        }
    }, []);

    let total = products.reduce((somme, product) => somme + parseFloat(product.Prix), 0);

    let content = '';
    content =
        <div>
            <section className="h-100 h-custom" style={{ backgroundColor: '#eee', display: partnerState.status === false ? '' : 'none' }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col">
                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="row">
                                        <h1 className="title" style={{ backgroundImage: `url(${context.rootdns}/SVGs/Cadre2.svg)` }}>Mon Panier</h1>
                                        <div className="col-lg-7">
                                            <h5 className="mb-3" style={{ textAlign: "left" }}>
                                                <Link to="/list" className="text-body">
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} className="me-2" />
                                                    Continuer les achats
                                                </Link>
                                            </h5>
                                            <hr />

                                            <div style={{ textAlign: "left" }} className="d-flex justify-content-between align-items-center mb-4">
                                                <div>
                                                    <p className="mb-0">Vous avez {products.length} article(s) dans votre panier</p>
                                                </div>
                                                <div>
                                                    <p className="mb-0"><span className="text-body">Trier par :&nbsp;</span> <a href="#!" className="text-body">
                                                        Prix <FontAwesomeIcon icon={faAngleDown} className="mt-1" />

                                                    </a></p>
                                                </div>

                                            </div>
                                            {products.map((product) =>
                                                <CartElement product={product} setTriggerCartChange={setTriggerCartChange} setProducts={setProducts} />
                                            )}
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="card  text-black rounded-3">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                                        <h2 className="mb-0">Total</h2>
                                                    </div>
                                                    <form className="mt-4">
                                                        <div className="d-flex justify-content-between">
                                                            <p className="mb-2">Sous-total</p>
                                                            <p className="mb-2">{(total * 0.9).toFixed(2)} €</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <p className="mb-2">TVA</p>
                                                            <p className="mb-2">{(total * 0.1).toFixed(2)} €</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-4">
                                                            <p className="mb-2">Total(Incl. taxes)</p>
                                                            <p className="mb-2">{total.toFixed(2)} €</p>
                                                        </div>
                                                    </form>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="lg" id="dropdown-basic">
                                                            Choix de livraison...
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1">Point retrait</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-2">Mon adresse</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-3">Autre</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <hr className="my-4" />
                                                    <div className="cart-buttons">
                                                        <button type="button" onClick={() => setPay(true)} className="cart-buttons-payer">
                                                            <p>Payer&nbsp;{total.toFixed(2)} € <FontAwesomeIcon icon={faLongArrowAltRight} className="ms-2" /></p>
                                                        </button>
                                                        <button type="button" className="cart-buttons-promo">
                                                            <p>Code Promo ?</p>
                                                        </button>
                                                    </div>
                                                    {pay && (
                                                        <>
                                                            <p className="small mb-2">Choisissez votre moyen de paiement</p>
                                                            <div>
                                                                <a onClick={() => setMethodPay('cb')} type="submit" className="text-danger">
                                                                    <FontAwesomeIcon icon={faCcMastercard} className="fa-4x me-2" />
                                                                </a>
                                                                <a onClick={() => setMethodPay('cb')} type="submit" className="text-primary">
                                                                    <FontAwesomeIcon icon={faCcVisa} className="fa-4x me-2" />
                                                                </a>
                                                                <a onClick={() => setMethodPay('cb')} type="submit" className="text-info">
                                                                    <FontAwesomeIcon icon={faCcAmex} className="fa-4x me-2" />
                                                                </a>
                                                                <a onClick={() => setMethodPay('paypal')} type="submit" className="text-info">
                                                                    <FontAwesomeIcon icon={faCcPaypal} className="fa-4x" />
                                                                </a>
                                                            </div>
                                                            <Payment products={products} total={total.toFixed(2)} methodPay={methodPay} setPartnerState={setPartnerState} />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div style={{ display: partnerState.status == 'loading' ? '' : 'none' }}>
                <h3>Redirection vers notre partenaire ...</h3>
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{ justifyContent: 'center', height: ' 60vh' }}
                    wrapperClass="mt-5"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
        </div>
    return (
        <>
            {content}
        </>
    );
}

ShoppingCart.propTypes = {};

ShoppingCart.defaultProps = {};

export default ShoppingCart;
