import React, { useState } from 'react';
import ProductInfo from './ProductInfo/ProductInfo';
import ProductInfoFull from './ProductInfoFull/ProductInfoFull';
import './Product.css';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useGetFetch } from '../../core/Api';

function Product(props) {
    const [productsFull, setProductsFull] = useState([]);
    const [productsFood, setProductsFood] = useState([]);
    const [productsAcc, setProductsAcc] = useState([]);
    const navigate = useNavigate();

    const handleSeeMore = (category, animal) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        navigate({
            pathname: "/list",
            search: createSearchParams({
                sort: 'nom_asc',
                category : category,
                animal : animal
            }).toString()
        });
    };

    useGetFetch(process.env.REACT_APP_SERVER + "products/week", {}, (data) => { setProductsFull(data.products) });
    useGetFetch(process.env.REACT_APP_SERVER + "products/list", {category:'Friandise', animal: 'Tous'}, (data) => { setProductsFood(data.products) });
    useGetFetch(process.env.REACT_APP_SERVER + "products/list", {category:'Accessoire', animal: 'Tous'}, (data) => { setProductsAcc(data.products) });

    return (
        <main>
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1 style={{ fontSize: 50, fontWeight: 'normal' }} className="produit_animal">
                Notre Selection de la semaine
            </h1>

            <div className="product_full">
                {productsFull.map((product) =>
                    <ProductInfoFull product={product} />
                )}
            </div>

            <h1 className="produit_animal">Selections de produits alimentaires</h1>
            <div className="masterbox">
                {productsFood.map((product) =>
                    <ProductInfo product={product} />
                )}
            </div>
            <div className="voir_plus" data-aos="zoom-in" onClick={() => {handleSeeMore('Friandise', 'Tous')}}>
                <h2>
                    Voir Plus  ➜
                </h2>
            </div>

            <h1 className="produit_animal"> Selections d'accessoires</h1>
            <div className="masterbox">
                {productsAcc.map((product) =>
                    <ProductInfo product={product} />
                )}
            </div>
            <div className="voir_plus" data-aos="zoom-in" onClick={() => {handleSeeMore('Accessoire', 'Tous')}}>
                <h2>
                    Voir Plus  ➜
                </h2>
            </div>
        </main>

    );
}

Product.propTypes = {};

Product.defaultProps = {};

export default Product;
