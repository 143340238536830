import React from "react";
import "./Filter.css";

const Filter = (props) => {
  let filterscopy = { ...props.filters };
  
  const handleChange = (event, setValue, filtername) => {
    const value = event.target.value;
    filterscopy[filtername] = value;
    setValue(v => value);
    props.setFilters(filterscopy);
  };

  return (
    <div className="filter-container">
      <label className="filter-label" htmlFor="categorie-select">Filtrer par catégorie :</label>
      <select
        id="categorie-select"
        value={props.category}
        onChange={(e) => {
          handleChange(e, props.setCategory, "category");
        }}
        className="filter-select"
      >
        <option value="">Tous</option>
        <option value="Friandise">Alimentaire</option>
        <option value="Accessoire">Accessoire</option>
      </select>
      <label className="filter-label" htmlFor="animal-select">Filtrer par animal :</label>
      <select
        id="animal-select"
        value={props.animal}
        onChange={(e) => {
          handleChange(e, props.setAnimal, "animal");
        }}
        className="filter-select"
      >
        <option value="">Tous</option>
        <option value="Chat">Chat</option>
        <option value="Rongeur">Rongeur</option>
      </select>
      <label className="filter-label" htmlFor="tri-select">Trier par :</label>
      <select
        id="tri-select"
        value={props.tri}
        onChange={(e) => {
          handleChange(e, props.setTri, "sort");
        }}
        className="filter-select"
      >
        <option value="nom_asc">Nom (croissant)</option>
        <option value="nom_desc">Nom (décroissant)</option>
        <option value="prix_asc">Prix (croissant)</option>
        <option value="prix_desc">Prix (décroissant)</option>
        <option value="date_desc">Date (plus récent en premier)</option>
        <option value="date_asc">Date (plus ancien en premier)</option>
      </select>
    </div>
  );
};

export default Filter;