import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe("pk_test_51OZVcfCEuCgGay4x9vNHIVr07mr82AKRxKfn5IzwjHNEevAjFi0r0E6m12uztiNIy63lq1ROwkXdqijQhzEWinw3005RJPUZgr");

const Stripe = ({ products, total, setPartnerState }) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    fetch(process.env.REACT_APP_SERVER + "payment/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ total: total }),
    })
      .then((res) => {
        localStorage.setItem('stripeProcessingComplete', 'true');
        return res.json();
      })
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm products={products} clientSecret= {clientSecret} total={total} setPartnerState={setPartnerState}/>
        </Elements>
      )}
    </>
  );
}

export default Stripe;