import React from 'react';
import './Badge.css';

const Badge = ({ value }) => {
  // Condition pour déterminer la classe CSS en fonction de la valeur
  const badgeClass = value >= 0 ? 'badge-green' : 'badge-red';

  return <div className={`badge ${badgeClass}`}>{value}</div>;
};

export default Badge;