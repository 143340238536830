import React, { useState, useEffect, useRef } from 'react';
import { createSearchParams, useNavigate, useLocation } from "react-router-dom";
import LogoVector from "../../Logo-vector.svg";
import "./Garde.css";

function Garde() {
  const [opacity, setOpacity] = useState(1);
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const svgRef = useRef(null);

useEffect(() => {
  const timer = setTimeout(() => {
    setOpacity(0);
    setTimeout(() => {
    setShow(false);
    if (location.search.includes("reference")) {
          const params = new URLSearchParams(location.search);
          const reference = params.get("reference");
          navigate({
            pathname: "/productpage",
            search: createSearchParams({
              reference: reference,
            }).toString(),
          });
        } else {
          navigate("/home"); // naviguer vers "/home" après que l'animation soit terminée
        }
    }, 1000);
  }, 1000);
  // Itérer sur chaque élément du SVG et les cacher
  const svgElements = svgRef.current.querySelectorAll('*');
    svgElements.forEach((el, i) => {
      el.style.opacity = 0;
});

// Afficher chaque élément du SVG un par un
svgElements.forEach((el, i) => {
  setTimeout(() => {
    el.style.opacity = 1;
  }, 100 * i);
});

return () => clearTimeout(timer);
}, [location.search, navigate, setOpacity, svgRef]);

return (
<div className='svgcontainer'>
<img
  src={LogoVector}
  className="logosvg"
  style={{ opacity: opacity }}
  ref={svgRef}
/>
</div>
);
}

export default Garde;