import React, { useContext, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { postFetch } from '../../core/Api';
import { useSignIn } from 'react-auth-kit';
import { AppContext } from "../../App";
import "./Connect.css";
import { notify } from "../utils";


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Inséparables Studios SAS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const theme = createTheme();

function Connect(props) {
  const context = useContext(AppContext);
  const [triggerPanierCount, setTriggerPanierCount] = context.panierChange;
  const signIn = useSignIn();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const credentials = {
      email: data.get('email'),
      password: data.get('password'),
    };
    postFetch(process.env.REACT_APP_SERVER + "users/login", credentials, async (result) => {

      if (result.status === 200) {
        signIn(
          {
            token: result.token,
            expiresIn: 3600,
            tokenType: "Bearer",
            authState: { user: result.userdatas },
          }
        );
        notify('success', 'Connecté bienvue ' + result.userdatas.firstname + ' !');
        props.handleClose();
        setTriggerPanierCount(state => !state);
      } else {
        setErrorMessage("Adresse Email ou Mot de passe incorrect");
      }
    });
  };

  const [errorMessage, setErrorMessage] = useState("");

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '90vh' }}>
        <Grid>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className="logo">
              <img src="/images/miustache_logo.jpg" alt="logo" className="img-logo" />
            </div>
            <Typography component="h1" variant="h5">
              Connexion
            </Typography>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                padding="normal"
                required
                fullWidth
                id="email"
                label="Adresse Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Se souvenir de moi"
              />
              <Button className="btn btn-primary" onSubmit={{ handleSubmit }}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Connexion
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Mot de passe oublié ?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/signup" variant="body2">
                    {"Ou inscrivez-vous ici !"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Connect;