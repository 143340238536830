import React from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { paymentApproved } from "../PaymentConfirm";
import { useNavigate } from "react-router-dom";

const Paypal = ({ products, total, setPartnerState }) => {
  const auth = useAuthUser();
  const isauth = useIsAuthenticated();
  const navigate = useNavigate();

  //Paypal
  const createOrder = function () {
    setPartnerState({status : 'loading'});
    return fetch(process.env.REACT_APP_SERVER + "payment/create-order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ total: total }),
    })
      .then((response) => response.json())
      .then((order) => order.id);
  }

  const onApprove = function (data, actions) {
    if(isauth()) {
      paymentApproved(total, data.orderID, auth().user, 'paypal', products.map((p) => { return { ref: p.Reference, quantity: p.quantity } }))
      navigate("/paymentconfirm?mode=paypal");
    }
  }

  return (
    <PayPalScriptProvider options={{currency: "EUR", 'client-id' : "Aek2pfEvtonLBOcQpPQT1E-HEOdKfK8QOp439Ldp0DJ8KQsJMIhLOrS7b8NMr6yeMMIh6PtZdsHgodmL"}}>
        <PayPalButtons disabled={false} style={{ layout: "vertical" }} createOrder={createOrder} onCancel={() => setPartnerState({status : false})} onApprove={onApprove} />
    </PayPalScriptProvider>
  );
};

export default Paypal;