import React, { useState, useEffect } from "react";
import ProductForm from '../../components/ProductForm/ProductForm';
import ProductFormMobile from '../../components/ProductForm/ProductFormMobile';
import { useSearchParams } from 'react-router-dom';
import { createSearchParams, useNavigate } from "react-router-dom";
import ProductInfo from '../../components/Product/ProductInfo/ProductInfo';
import '../../components/Product/ProductInfo/ProductInfo.css';
import Commentaire from '../../components/Commentaire/Commentaire';
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import moment from "moment";
import { useGetFetch } from "../../core/Api";

function ProductPage(props) {
    const [product, setProduct] = useState({
        Reference: null,
        NomProduit: '',
        Description: '',
        Prix: '',
        Image: '',
        Quantite: 1,
        DateDePeremption: moment().add(1, 'months').format('YYYY-MM-DD'),
        Marque: '',
        Option: '',
        TypeArticle: '',
        note: '',
        nbrnotes: '',
        DateMiseEnLigne: '',
        TypeDeProduit: 'Friandise',
        Theme: 'Chat'
    });
    const [commentaire, setCommentaire] = useState([]);
    const [productsSuggestions, setProductsSuggestions] = useState([]);
    const [queryParameters] = useSearchParams() // Paramètres dans l'url
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const auth = useAuthUser();
    const isauth = useIsAuthenticated();
    const role = isauth() ? auth().user.role : '';

    /*useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1100);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);*/

    let mode = role == 'admin' && queryParameters.get("mode") && ['edit', 'add'].includes(queryParameters.get("mode")) ? queryParameters.get("mode") : 'readonly';
    if (mode == 'add') {
        mode = 'edit';
    } else {
        useGetFetch(process.env.REACT_APP_SERVER + "products/suggestions", { reference: queryParameters.get("reference") }, (datas) => { setProductsSuggestions(datas.product) });
        useGetFetch(process.env.REACT_APP_SERVER + "products/singleproduct", { reference: queryParameters.get("reference") }, (datas) => { setProduct(datas.product) });
        useGetFetch(process.env.REACT_APP_SERVER + "products/commentaire", { reference: queryParameters.get("reference") }, (datas) => { setCommentaire(datas.product) });
    }

    const handleSeeMore = (animal) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate({
            pathname: "/list",
            search: createSearchParams({
                sort: "nom_asc",
                category: "Tous",
                animal: product.Theme,
            }).toString(),
        });
    };

    return (
        <div className="ProductPage">
            {isMobile ? (
                <div className="ProductPage">
                    <main>
                        <ProductFormMobile product={product} setProduct={setProduct} />
                        <Commentaire commentaire={commentaire} setCommentaire={setCommentaire} />
                        <div>
                            <h1 className="produit_animal">Suggestions</h1>
                            <div className="masterbox">
                                {productsSuggestions.map((product) =>
                                    <ProductInfo product={product} />
                                )}
                            </div>
                            <div className="voir_plus" data-aos="zoom-in" onClick={handleSeeMore}>
                                <h2>
                                    Voir Plus  ➜
                                </h2>
                            </div>
                        </div>
                    </main>
                </div>
            ) : (
                <div className="ProductPage">
                    <main>
                        <ProductForm mode={mode} product={product} setProduct={setProduct} />
                        <Commentaire commentaire={commentaire} setCommentaire={setCommentaire} />
                        <div>
                            <h1 className="produit_animal">Suggestions</h1>
                            <div className="masterbox">
                                {productsSuggestions.map((product) =>
                                    <ProductInfo product={product} />
                                )}
                            </div>
                            <div className="voir_plus" data-aos="zoom-in" onClick={handleSeeMore}>
                                <h2>
                                    Voir Plus  ➜
                                </h2>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </div>
    );
}

ProductPage.propTypes = {};

ProductPage.defaultProps = {};

export default ProductPage;
