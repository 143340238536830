import React, { useContext, useEffect } from 'react';
import "./Boxsurprise.css"
import { createSearchParams, useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';


function Boxsurprise(props) {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const handleSeeMore = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    navigate("/boxsurprise/open/chat");
  };


  return (
    <div className="boxsurprise">
      <img
        src="/images/boxsurprise.jpeg" draggable="false"
        className="imgsurprise"
      />
      <div className="boxcontent">
        <h1 className="boxtitle" style={{ backgroundImage: `url(${context.rootdns}/SVGs/Cadre2.svg)` }}>
          Box Surprise
        </h1>
        <div className="boxquestion">
          <h2>Qu'est ce qu'une Box Surprise ?</h2>
        </div>
        <div className="boxtext">
          <p>Offrez à votre animal une expérience de dégustation unique grâce à nos box surprises, une sélection savoureuse et équilibrée d'aliments pour chat ou chien, pour une découverte gustative et une variété dans leur alimentation !</p>
        </div>
        <button className='box_week_a' onClick={() => { handleSeeMore() }}>Commandez votre Box Surprise</button>
      </div>
    </div>
  );
}

Boxsurprise.propTypes = {};

Boxsurprise.defaultProps = {};

export default Boxsurprise;