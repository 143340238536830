import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import './Signup.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { postFetch } from '../../core/Api';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaw } from "@fortawesome/free-solid-svg-icons";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" style={{marginTop: '0px;'}} align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Inseparables Studios SAS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function LeftArrowButton() {
  const navigate = useNavigate();
  const [pulse, setPulse] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setPulse((prevPulse) => !prevPulse);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = () => {
    navigate("/home");
  };

  return (
    <button
      onClick={handleButtonClick}
      style={{
        position: "fixed",
        top: "30px",
        left: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: pulse ? "#fff" : "#fff",
        color: pulse ? "#f0b9b4" : "#f0b9b4",
        border: "2px solid #f0b9b4",
        borderRadius: "30%",
        width: "110px",
        height: "68.75px",
        outline: "none",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        transform: pulse ? "translateX(10px)" : "translateX(0px)",
        transition: "transform 1s ease-in-out",
      }}
    >
      <FontAwesomeIcon
        icon={faPaw}
        style={{ fontSize: "30px", marginRight: "6px" }}
      />
      <span style={{ fontSize: "18px" }}>Retour</span>
    </button>
  );
}


const theme = createTheme();


export default function SignUp() {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    postFetch(process.env.REACT_APP_SERVER + 'users/create-user', {
      email: data.get('email'),
      password: data.get('password'),
      address: data.get('address'),
      firstName: data.get('firstName'),
      lastName: data.get('lastName')
    }, (res) => {
      const errorMessage = document.querySelector('.error-message');
      if (errorMessage) {
        errorMessage.remove();
      }
      if (res.status === 409 || res.status === 422) {
        const newErrorMessage = document.createElement('div');
        newErrorMessage.textContent = res.message;
        newErrorMessage.classList.add('error-message');
        newErrorMessage.style.color = 'red';
        document.querySelector('form').insertAdjacentElement('beforebegin', newErrorMessage);
      } else {
        navigate('/Home');
      }
    });
  };
  
  
  return (
    <ThemeProvider theme={theme} >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <LeftArrowButton />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: 'white',
            padding: '50px',
            marginLeft: '-50px',
            marginRight: '-50px',
            marginBottom: '-70px',
          }}
        >
          <div className="logo">
            <img src="/images/miustache_logo.jpg" alt="logo" className="img-logo" />
          </div>
          <Typography component="h1" variant="h5">
            S'inscrire
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Prénom"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Nom de famille"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Adresse Email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Adresse"
                  name="address"
                  autoComplete="address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Mot de Passe"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="Je souhaite recevoir des promotions marketing et des mises à jour par Email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              // onClick={() => navigate('/Home')}
            >
              S'inscrire
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/Home" variant="body2" >
                  Déjà un compte ? Connectez-vous ici !
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}