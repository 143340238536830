import React from "react";
import "./ProductInfo.css";
import PropTypes from "prop-types";
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';

function ProductInfo(props) {
  let product = props.product;
  const navigate = useNavigate();
  let image = process.env.REACT_APP_SERVER + product.Image.split(';')[0];
  const auth = useAuthUser();
  const isauth = useIsAuthenticated();
  const role = isauth() ? auth().user.role : "";

  const handleSeeMore = (ref) => {

    navigate({
      pathname: "/productpage",
      search: createSearchParams({
        reference: ref
      }).toString()
    });
    window.location.reload(true);
  };

  const handleSeeMoreModif = (e, ref, mode) => {
    e.stopPropagation();
    navigate({
      pathname: "/productpage",
      search: createSearchParams({
        reference: ref,
        mode: mode
      }).toString()
    });
    window.scrollTo(0, 0);
  };


  if (role === 'admin') {
    return (
      <div className="box-container">
        <button className="box" data-aos="zoom-in" onClick={() => { handleSeeMore(product.Reference) }}>
          <img draggable="false" src={image} width="280" height="340" />
          <h2 className="box-product-title">{product.NomProduit}</h2>
          <div className="rate">
            <input type="radio" id="star5" name="rate" value="5" />
            <label htmlFor="star5" title="text">
              5 stars
            </label>
            <input type="radio" id="star4" name="rate" value="4" />
            <label htmlFor="star4" title="text">
              4 stars
            </label>
            <input type="radio" id="star3" name="rate" value="3" />
            <label htmlFor="star3" title="text">
              3 stars
            </label>
            <input type="radio" id="star2" name="rate" value="2" />
            <label htmlFor="star2" title="text">
              2 stars
            </label>
            <input type="radio" id="star1" name="rate" value="1" />
            <label htmlFor="star1" title="text">
              1 star
            </label>
          </div>
          <h2 className='product-price'>{product.Prix} <span> €</span></h2>
          <button className='box_week_a' onClick={(e) => { handleSeeMoreModif(e, product.Reference, 'edit') }}>Modifier</button>
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <button className="box" data-aos="zoom-in" onClick={() => { handleSeeMore(product.Reference) }}>
          <img draggable="false" src={image} width="280" height="340" />
          <h2 className="box-product-title">{product.NomProduit}</h2>
          <div className="rate">
            <input type="radio" id="star5" name="rate" value="5" />
            <label htmlFor="star5" title="text">
              5 stars
            </label>
            <input type="radio" id="star4" name="rate" value="4" />
            <label htmlFor="star4" title="text">
              4 stars
            </label>
            <input type="radio" id="star3" name="rate" value="3" />
            <label htmlFor="star3" title="text">
              3 stars
            </label>
            <input type="radio" id="star2" name="rate" value="2" />
            <label htmlFor="star2" title="text">
              2 stars
            </label>
            <input type="radio" id="star1" name="rate" value="1" />
            <label htmlFor="star1" title="text">
              1 star
            </label>
          </div>
          <h2 className="product-price">{product.Prix} <span> €</span></h2>
        </button>
      </div>
    );
  }
}

ProductInfo.propTypes = {};

ProductInfo.defaultProps = {};

export default ProductInfo;
