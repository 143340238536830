import React, { useContext, useState } from "react";
import ".//OrderTracking.css";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faLongArrowAltLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";
import Stepper from 'react-stepper-horizontal'
import moment from "moment";
import { useGetFetch } from "../../core/Api";

function OrderElement(props) {
    let product = props.product;
    return (
        <div className="p-3 col-lg-6">
            <div className="card mb-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between cart-product-left">
                        <div className="d-flex flex-row align-items-center">
                            <div className="cart-product-image">
                                <img draggable="false"
                                    src={process.env.REACT_APP_SERVER + product.Image.split(';')[0]}
                                    className="rounded-3"
                                    alt="Shopping item"
                                    style={{ width: '200px' }}
                                />
                            </div>
                            <div className="ms-3 cart-product-info">
                                <h5>{product.NomProduit}</h5>
                                <div className="rate">
                                    <input type="radio" id="star5" name="rate" value="5" />
                                    <label htmlFor="star5" title="text">5 stars</label>
                                    <input type="radio" id="star4" name="rate" value="4" />
                                    <label htmlFor="star4" title="text">4 stars</label>
                                    <input type="radio" id="star3" name="rate" value="3" />
                                    <label htmlFor="star3" title="text">3 stars</label>
                                    <input type="radio" id="star2" name="rate" value="2" />
                                    <label htmlFor="star2" title="text">2 stars</label>
                                    <input type="radio" id="star1" name="rate" value="1" />
                                    <label htmlFor="star1" title="text">1 star</label>
                                </div>
                                <p className="small mb-0">
                                    {product.Description.length > 50
                                        ? product.Description.substring(0, 100) + "..."
                                        : product.Description}
                                </p>
                            </div>
                        </div>
                        <div className="cart-price-quantity-cross" >
                            <div style={{ width: 'auto' }}>
                                <h5 className="fw-normal mb-0">
                                    <div className="quantite" style={{ display: "flex", alignItems: "center" }}>
                                        <span>x {product.Quantity}</span>
                                    </div>
                                </h5>
                            </div>
                            <div style={{ width: '80px' }}>
                                <p className="cart-price">{(product.Prix * product.Quantity).toFixed(2)} €</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OrderTracking(props) {
    const context = useContext(AppContext);
    const [orders, setOrders] = useState([]);
    const auth = useAuthUser();
    const isauth = useIsAuthenticated();
    useGetFetch(process.env.REACT_APP_SERVER + "products/getorders", { idclient: isauth() ? auth().user.id : 0 }, (data) => {
        setOrders(data.orders)
    }, []);
    const steps = [{
        title: 'En attente',
        /*href: 'http://example1.com',
        onClick: (e) => {
          e.preventDefault()
          console.log('onClick', 1)
        }*/
      }, {
        title: 'Expedié'
      }, {
        title: 'En cours de livraison'
      }, {
        title: 'Livré'
      }];
    const status = ['waiting', 'sent', 'delivprocess', 'delivred']
    let content =
        <section className="h-100 h-custom" style={{ backgroundColor: '#eee' }}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col">
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="row">
                                    <h1 className="title" style={{backgroundImage: `url(${context.rootdns}/SVGs/Cadre2.svg)`}}>Mes commandes</h1>
                                    <div>
                                        <h5 className="mb-3" style={{ textAlign: "left" }}>
                                            <Link to="/list" className="text-body">
                                                <FontAwesomeIcon icon={faLongArrowAltLeft} className="me-2" />
                                                Liste des produits
                                            </Link>
                                        </h5>
                                        <hr />

                                        <div style={{ textAlign: "left" }} className="d-flex justify-content-between align-items-center mb-4">
                                            <div>
                                                <p className="mb-0"><span className="text-body">Trier par :&nbsp;</span> <a href="#!" className="text-body">
                                                    Date <FontAwesomeIcon icon={faAngleDown} className="mt-1" />
                                                </a></p>
                                            </div>

                                        </div>
                                        {
                                            orders.map((order)=>{
                                                return <div className="row orderpanel mb-5 p-3">
                                                    <p>Commandé le {moment(order.date).format('DD/MM/yyyy à HH:mm')}</p><br/>
                                                    <Stepper steps={steps} activeStep={status.indexOf(order.status)}/>
                                                    {order.products.map((product) =>
                                                        <OrderElement product={product} />
                                                    )}
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    return (
        <>
            {content}
        </>
    );
}

OrderTracking.propTypes = {};

OrderTracking.defaultProps = {};

export default OrderTracking;
