import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./Categories.css";
import { createSearchParams, useNavigate, useLocation } from "react-router-dom";
import ProductInfo from '../../components/Product/ProductInfo/ProductInfo';
import { AppContext } from "../../App";
import { useGetFetch } from "../../core/Api";



function Categories() {
  useGetFetch(process.env.REACT_APP_SERVER + "products/list", {category:'Friandise', animal: 'Chat'}, (data) => { setProductsFoodChat(data.products) });
  useGetFetch(process.env.REACT_APP_SERVER + "products/list", {category:'Accessoire', animal: 'Chat'}, (data) => { setProductsAccChat(data.products) });
  useGetFetch(process.env.REACT_APP_SERVER + "products/list", {category:'Friandise', animal: 'Rongeur'}, (data) => { setProductsFoodRongeur(data.products) });
  useGetFetch(process.env.REACT_APP_SERVER + "products/list", {category:'Accessoire', animal: 'Rongeur'}, (data) => { setProductsAccRongeur(data.products) });
  const context = useContext(AppContext);
  const [productsFoodChat, setProductsFoodChat] = useState([]);
  const [productsAccChat, setProductsAccChat] = useState([]);
  const [productsFoodRongeur, setProductsFoodRongeur] = useState([]);
  const [productsAccRongeur, setProductsAccRongeur] = useState([]);
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const handleSeeMore = (category, animal) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    navigate({
      pathname: "/list",
      search: createSearchParams({
        sort: 'nom_asc',
        category: category,
        animal: animal
      }).toString()
    });
  };


  if (location === "/chat") {
    return (
      <div className="container-categories">
        <h1 className="animal_title" style={{backgroundImage: `url(${context.rootdns}/SVGs/Cadre2.svg)`}}>Les Chats</h1>
        <img
            src= { process.env.REACT_APP_SERVER + "images/produit_14.jpg" }
            className="animal_picture" 
            />
        <div class="content-slider">
          <div class="slider">
            <div class="mask">
              <ul>
                <li class="anim1">
                  <div class="quote">Nous sommes fiers de vous offrir une expérience d'achat en ligne exceptionnelle pour tous les amoureux des chats, avec notre sélection de nourriture saine et savoureuse, ainsi que des accessoires pratiques et stylés pour votre petit félin préféré !</div>
                </li>
                <li class="anim2">
                  <div class="quote">Parce que votre chat mérite ce qu'il y a de mieux, découvrez notre gamme d'aliments premium et d'accessoires haut de gamme pour le rendre heureux et en pleine forme !</div>
                </li>
                <li class="anim3">
                  <div class="quote">Transformez votre maison en un véritable paradis pour votre chat avec notre sélection d'aliments savoureux et d'accessoires indispensables pour son bien-être !</div>
                </li>
                <li class="anim4">
                  <div class="quote">Parce que la santé et le bonheur de votre chat sont notre priorité, nous avons sélectionné pour vous les meilleures marques d'aliments et d'accessoires pour offrir à votre compagnon à quatre pattes tout ce dont il a besoin pour une vie épanouie et en pleine santé !</div>
                </li>
                <li class="anim5">
                  <div class="quote">Offrez à votre chat un festin royal avec notre sélection de nourriture gourmande et de snacks délicieux, accompagnée d'accessoires tendance et fonctionnels pour une vie de chat heureux et comblé !</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <h1 className="produit_animal">Selections de produits alimentaires</h1>
        <div className="masterbox">
          {productsFoodChat.map((product) =>
              <ProductInfo product={product} />
          )}
        </div>
        <div className="voir_plus" data-aos="zoom-in" onClick={() => { handleSeeMore('Friandise', 'Chat') }}>
          <h2>
            Voir Plus  ➜
          </h2>
        </div>

        <h1 className="produit_animal"> Selections d'accessoires</h1>
        <div className="masterbox">
          {productsAccChat.map((product) =>
            <ProductInfo product={product} />
          )}
        </div>
        <div className="voir_plus" data-aos="zoom-in" onClick={() => { handleSeeMore('Accessoire', 'Chat') }}>
          <h2>
            Voir Plus  ➜
          </h2>
        </div>
      </div>
    );
  } else if (location === "/rongeur") {
    return (
      <div className="container-categories">
        <h1 className="animal_title" style={{backgroundImage: `url(${context.rootdns}/SVGs/Cadre2.svg)`}}>Les Rongeurs</h1>
        <img
            src= { process.env.REACT_APP_SERVER + "images/produit_12.jpg" }
            className="animal_picture" 
            />
        <div class="content-slider">
          <div class="slider">
            <div class="mask">
              <ul>
                <li class="anim1">
                  <div class="quote">Parce que tous les petits compagnons méritent une alimentation saine et équilibrée, découvrez notre sélection de nourriture pour rongeurs, accompagnée d'accessoires ludiques pour leur offrir un environnement confortable et stimulant !</div>
                </li>
                <li class="anim2">
                  <div class="quote">Nous sommes fiers de vous proposer une gamme complète d'aliments pour rongeurs de qualité supérieure, accompagnée d'accessoires utiles et amusants pour offrir à votre petit compagnon un quotidien riche en découvertes et en bien-être !</div>
                </li>
                <li class="anim3">
                  <div class="quote">Donnez à votre rongeur une alimentation adaptée à ses besoins et à ses goûts, grâce à notre sélection de nourriture savoureuse et variée, ainsi que des accessoires pratiques pour améliorer son confort et sa sécurité.</div>
                </li>
                <li class="anim4">
                  <div class="quote">Chez nous, nous avons sélectionné les meilleurs produits pour votre rongeur, des aliments de qualité supérieure aux accessoires astucieux pour leur offrir un environnement propice à leur bien-être et leur épanouissement !</div>
                </li>
                <li class="anim5">
                  <div class="quote">Pour une vie de rongeur saine et heureuse, optez pour notre sélection de nourriture naturelle et de qualité, ainsi que des accessoires pratiques et design pour un environnement de vie stimulant et confortable !</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <h1 className="produit_animal">Selections de produits alimentaires</h1>
        <div className="masterbox">
          {productsFoodRongeur.map((product) =>
            <ProductInfo product={product} />
          )}
        </div>
        <div className="voir_plus" data-aos="zoom-in" onClick={() => { handleSeeMore('Friandise', 'Rongeur') }}>
          <h2>
            Voir Plus  ➜
          </h2>
        </div>


        <h1 className="produit_animal"> Selections d'accessoires</h1>
        <div className="masterbox">
          {productsAccRongeur.map((product) =>
            <ProductInfo product={product} />
          )}
        </div>
        <div className="voir_plus" data-aos="zoom-in" onClick={() => { handleSeeMore('Accessoire', 'Rongeur') }}>
          <h2>
            Voir Plus  ➜
          </h2>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

Categories.propTypes = {};

Categories.defaultProps = {};

export default Categories;
