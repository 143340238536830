import React, { useState, useRef, useEffect } from 'react';
import './Carousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faTrash } from '@fortawesome/free-solid-svg-icons'


function Carousel(props) {
  const [activeImage, setActiveImage] = useState(0);
  const [activeThumbnail, setActiveThumbnail] = useState(0);
  const thumbnailWrapperRef = useRef(null);
  let product = props.product;
  let l = [];

  if (product && product.Image) {
    l = product.Image.split(";");
  }

  const images = [
    { id: 1, src: process.env.REACT_APP_SERVER + l[0], alt: "Image 1" },
    { id: 2, src: process.env.REACT_APP_SERVER + l[1], alt: "Image 2" },
    { id: 3, src: process.env.REACT_APP_SERVER + l[2], alt: "Image 3" },
    { id: 4, src: process.env.REACT_APP_SERVER + l[3], alt: "Image 4" },
  ];

  useEffect(() => {
    // Fait défiler la vignette active jusqu'au centre du conteneur de vignettes
    if (thumbnailWrapperRef.current) {
      const thumbnailWidth = thumbnailWrapperRef.current.children[0].offsetWidth;
      thumbnailWrapperRef.current.scrollTo({
        left: (activeThumbnail - 1) * thumbnailWidth,
        behavior: 'smooth'
      });
    }
  }, [activeThumbnail]);

  const handleImageClick = (index) => {
    setActiveImage(index);
    setActiveThumbnail(index);
  };

  const handleThumbnailClick = (index) => {
    setActiveImage(index);
    setActiveThumbnail(index);
  };


  const renderCarouselImages = () =>
    images.map((image, index) => (
      <img
        key={image.id}
        src={image.src}
        alt={image.alt}
        draggable="false"
        className={`carousel-image ${activeImage === index ? 'active' : ''}`}
        onClick={() => handleImageClick(index)}
      />
    ));


  const handlePrevClick = () => {
    setActiveThumbnail(Math.max(activeThumbnail - 1, 0));
    setActiveImage(Math.max(activeImage - 1, 0));
  };

  const handleNextClick = () => {
    setActiveThumbnail(Math.min(activeThumbnail + 1, images.length - 1));
    setActiveImage(Math.min(activeImage + 1, images.length - 1));
  };


  const handleDeleteClick = (index) => {
    const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette image ?");
    if (confirmed) {
      const imagesCopy = [...images];
      const imageToDelete = imagesCopy[index];
  
      // Supprime l'image en fonction de son chemin
      const filteredImages = imagesCopy.filter(image => image.src !== imageToDelete.src);
  
      setActiveThumbnail(Math.min(activeThumbnail, filteredImages.length - 1));
      setActiveImage(Math.min(activeImage, filteredImages.length - 1));
    }
  };
  

  const renderVisibleThumbnails = () => {
    const startIndex = Math.max(activeThumbnail - 1, 0);
    const endIndex = Math.min(startIndex + 3, images.length);
    return images.slice(startIndex, endIndex).map((image, index) => (
      <div key={image.id} className="thumbnail-container">
        <img
          src={image.src}
          alt={image.alt}
          draggable="false"
          className={`carousel-thumbnail ${activeThumbnail === index + startIndex ? 'active' : ''}`}
          onClick={() => handleThumbnailClick(index + startIndex)}
        />
        {props.mode == "edit" && 
        <button className="delete-button" onClick={() => handleDeleteClick(index)}>
          &#10006;
        </button>
        }
      </div>
    ));
  };

  return (
    <div className="carousel-container">
      <br/>
      <br/>
      <div className="carousel-product">
        {renderCarouselImages()}
      </div>
      <div className="carousel-thumbnails">
        <button
          className={`carousel-nav left ${activeThumbnail === 0 ? 'disabled' : ''}`}
          onClick={handlePrevClick}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>

        <div className="thumbnail-wrapper">
          <h1>.</h1>
        </div>

        <button
          className={`carousel-nav right ${activeThumbnail === images.length - 1 ? 'disabled' : ''}`}
          onClick={handleNextClick}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>

      </div>
      {props.mode == "edit" && 
      <input type="file" className="fileupload mt-5" onChange={props.handleFileUpload} multiple />}
    </div>
  );
  

}

export default Carousel;