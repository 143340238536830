import React, { useContext, useEffect, useRef, useState } from 'react';
import './ProductInfoFull.css';
import PropTypes from 'prop-types';
import useGetFetch, { postFetch } from '../../../core/Api';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import { AppContext } from '../../../App';



function ProductInfoFull(props) {
    const [panierCounter, setPanierCounter] = useContext(AppContext).panier;
    const auth = useAuthUser();
    const isauth = useIsAuthenticated();
    const navigate = useNavigate();
    let product = props.product;
    let image = process.env.REACT_APP_SERVER + product.Image.split(';')[0];
    let idclient = isauth() ? auth().user.id : 0;
    const role = isauth() ? auth().user.role : "";
    const [ratings, setRatings] = useState([]);


      

      const calculerMoyenne = (ratings) => {
        if (ratings.length === 0) {
          return 0;
        }
      
        const sommeNotes = ratings.reduce((total, rating) => total + rating.note, 0);
        const moyenne = sommeNotes / ratings.length;
      
        return moyenne;
      };
      

    
    const addToCart = (productid, idclient) => {
        postFetch(process.env.REACT_APP_SERVER + "products/setcart", { idclient: idclient, idproduct: productid, action : "add" }, () => { setPanierCounter(panierCounter + 1) })
    }
    
    const handleSeeMore = (ref, mode) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        navigate({
            pathname: "/productpage",
            search: createSearchParams({
                reference: ref,
                mode: mode
            }).toString()
        });
    };

    if (role === 'admin') {
        return (
            
            <div>
            <div className="box_week" data-aos="zoom-in">
                <img draggable="false" src={image} />
                <div className="flex-text-down">
                    <p className='box-title'>{product.NomProduit}
                    <div className="rate">
                        <input type="radio" id="star5" name="rate" value="5" />
                        <label htmlFor="star5" title="text">5 stars</label>
                        <input type="radio" id="star4" name="rate" value="4" />
                        <label htmlFor="star4" title="text">4 stars</label>
                        <input type="radio" id="star3" name="rate" value="3" />
                        <label htmlFor="star3" title="text">3 stars</label>
                        <input type="radio" id="star2" name="rate" value="2" />
                        <label htmlFor="star2" title="text">2 stars</label>
                        <input type="radio" id="star1" name="rate" value="1" />
                        <label htmlFor="star1" title="text">1 star</label>
                    </div>
                    </p>
                    <p className='box-desc'>{product.Description.length > 50
                                ? product.Description.substring(0, 100) + "..."
                                : product.Description}</p>
                    <p className='box-price'>
                        {product.Prix} <span> €</span>
                    </p>
                    <div className="plusetpanier">
                    <button className='box_week_plus' onClick={() => { handleSeeMore(product.Reference, 'readonly') }}>Voir plus</button>
                    <a className='box_week_a' onClick={() => { addToCart(props.product.Reference, idclient) }}>Ajouter au panier</a>
                    <button className='box_week_mod' onClick={() => { handleSeeMore(product.Reference, 'edit') }}>Modifier</button>
                    </div>
                </div>
            </div>
            
            </div>
            
        );
    } else {
        return (
            
            <div>
            <div className="box_week" data-aos="zoom-in">
                <img draggable="false" src={image} />
                <div className="flex-text-down">
                    <p className='box-title'>{product.NomProduit}

                    </p>
                    <p className='box-desc'>{product.Description.length > 50
                                ? product.Description.substring(0, 100) + "..."
                                : product.Description}</p>
                    <p className='box-price'>
                        {product.Prix} <span> €</span>
                    </p>
                    <div className="plusetpanier">
                    <button className='box_week_plus' onClick={() => { handleSeeMore(product.Reference, 'readonly') }}>Voir plus</button>
                    <a className='box_week_a' onClick={() => { addToCart(props.product.Reference, idclient) }}>Ajouter au panier</a>
                    </div>
                </div>
            </div>
            
            </div>
             
          
        );
    }
}

ProductInfoFull.propTypes = {};

ProductInfoFull.defaultProps = {};

export default ProductInfoFull;
