import React, { useState, useRef, useEffect } from 'react';
import './Carousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faTrash } from '@fortawesome/free-solid-svg-icons'


function Carousel(props) {
  const [activeImage, setActiveImage] = useState(0);
  const [activeThumbnail, setActiveThumbnail] = useState(0);
  const [images, setImages] = useState([]);
  const [triggerUptCarousel, setTriggerUptCarousel] = useState(false);
  const thumbnailWrapperRef = useRef(null);
  let product = props.product;

  const manageImages = function (images, b64 = false) {
    let imgprefix = b64 ? '' : process.env.REACT_APP_SERVER;
    let imgs = images.map((img, index) => {
      return { id: index, src: imgprefix + img, alt: "Image" + index };
    });
    setImages((prevImages) => {
      let arr = prevImages.concat(imgs).reverse();
      arr = Array.from(new Set(arr.map(item => item.src))).map(src => {
        return arr.find(item => item.src === src);
      });
      const result = arr.map((objet, index) => {
        return { ...objet, id: index };
      });
      return result;
    });
  }

  useEffect(() => {
    if (product && product.Image) {
      manageImages(product.Image.split(';'));
    }
  }, [triggerUptCarousel, product]);

  useEffect(() => {
    // Fait défiler la vignette active jusqu'au centre du conteneur de vignettes
    if (thumbnailWrapperRef.current && thumbnailWrapperRef.current.children[0]) {
      const thumbnailWidth = thumbnailWrapperRef.current.children[0].offsetWidth;
      thumbnailWrapperRef.current.scrollTo({
        left: (activeThumbnail - 1) * thumbnailWidth,
        behavior: 'smooth'
      });
    }
  }, [activeThumbnail]);

  const handleImageClick = (index) => {
    setActiveImage(index);
    setActiveThumbnail(index);
  };

  const handleThumbnailClick = (index) => {
    setActiveImage(index);
    setActiveThumbnail(index);
  };

  const renderCarouselImages = () =>
    images.map((image, index) => (
      <img
        key={image.id}
        src={image.src}
        alt={image.alt}
        draggable="false"
        className={`carousel-image ${activeImage === index ? 'active' : ''}`}
        onClick={() => handleImageClick(index)}
      />
    ));

  const handlePrevClick = (e) => {
    e.preventDefault();
    setActiveThumbnail(Math.max(activeThumbnail - 1, 0));
    setActiveImage(Math.max(activeImage - 1, 0));
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    setActiveThumbnail(Math.min(activeThumbnail + 1, images.length - 1));
    setActiveImage(Math.min(activeImage + 1, images.length - 1));
  };

  const onUploadFile = (e) => {
    props.handleFileUpload(e);
    const promises = [];
    Array.from(e.target.files).forEach((file) => {
      const promise = new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(file);
      });
      promises.push(promise);
    });

    Promise.all(promises).then((results) => {
      manageImages(results, true);
    });
  }

  const handleDeleteClick = (index) => {
    //const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette image ?");
    //if (confirmed) {
    const imagesCopy = [...images];
    const imageToDelete = imagesCopy[index];
    console.log(imageToDelete);
    props.setImagesDelete([...props.imagesDelete, imageToDelete]);
    // Supprime l'image en fonction de son chemin
    let filteredImages = imagesCopy.filter(image => image.src !== imageToDelete.src);
    // recalculer les id
    filteredImages.map((img, index) => { return { id: index, src: img } });
    setImages(filteredImages);
    setActiveThumbnail(Math.min(activeThumbnail, filteredImages.length - 1));
    setActiveImage(Math.min(activeImage, filteredImages.length - 1));
    //}
  };

  const renderVisibleThumbnails = () => {
    const imagesCopy = [...images];
    const startIndex = Math.max(activeThumbnail - 1, 0);
    const endIndex = Math.min(startIndex + 3, images.length);
    return images.slice(startIndex, endIndex).map((image, index) => (
      <div key={image.id} className="thumbnail-container">
        <img
          src={image.src}
          alt={image.alt}
          draggable="false"
          className={`carousel-thumbnail ${activeThumbnail === index + startIndex ? 'active' : ''}`}
          onClick={() => handleThumbnailClick(index + startIndex)}
        />
        {props.mode == "edit" &&
          <div className="delete-button" style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(index + startIndex)}>
            &#10006;
          </div>
        }
      </div>
    ));
  };

  return (
    <div className="carousel-container">
      <br />
      <br />
      <div className="carousel-product">
        {renderCarouselImages()}
      </div>
      <div className="carousel-thumbnails">
        <button
          className={`carousel-nav left ${activeThumbnail === 0 ? 'disabled' : ''}`}
          onClick={handlePrevClick}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>

        <div className="thumbnail-wrapper" ref={thumbnailWrapperRef}>
          {renderVisibleThumbnails()}
        </div>

        <button
          className={`carousel-nav right ${activeThumbnail === images.length - 1 ? 'disabled' : ''}`}
          onClick={handleNextClick}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>

      </div>
      {props.mode == "edit" &&
        <input type="file" className="fileupload mt-5" onChange={onUploadFile} multiple />}
    </div>
  );


}

export default Carousel;