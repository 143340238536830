import React from "react";
import "./Payment.css";
import Paypal from "./Paypal/Paypal";
import Stripe from "./Stripe/Stripe";


const Payment = ({ products, methodPay, total, setPartnerState }) => {

  let payChoice = '';

  switch (methodPay) {
    case 'paypal':
      payChoice =
        <Paypal products={products} total={total} setPartnerState={setPartnerState} />;
      break;
    case 'cb':
      if(total >= 1) {
        payChoice =
          <Stripe products={products} total={total} setPartnerState={setPartnerState} />;
      }
      break;
  }

  return (
    <div>
      {methodPay &&
        <div className="payment-container mt-5">
          {payChoice}
        </div>
      }
    </div>
  );
};

export default Payment;