import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faUser } from "@fortawesome/free-solid-svg-icons";
import "./Commentaire.css";

const CommentComponent = ({ commentaire }) => {
  const [showComments, setShowComments] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const commentsPerPage = 2;
  const totalSlides = Math.ceil(commentaire.length / commentsPerPage);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const displayComments = commentaire.slice(
    currentSlide * commentsPerPage,
    (currentSlide + 1) * commentsPerPage
  );

  const handleClick = () => {
    setShowComments(!showComments);
  };

  return (
    <div className='Commentaire'>
      <button className={`CommentaireBoutton ${showComments ? 'active' : ''}`} onClick={handleClick}>
        Commentaires ({commentaire.length})
      </button>
      <div className={`CommentaireList ${showComments ? 'show' : ''}`}>
        <h3 className="CommentaireTitle">Commentaires</h3>
        {displayComments.map((comment, index) => (
          <div key={index} className={`CommentaireItem ${showComments ? 'show' : ''}`}>
            <div className='commentaire-author-info'>
              <div className='Commentaire-pfp'><FontAwesomeIcon icon={faUser} /></div>
              <div className='commentaire-author-rate'>
                <p className="CommentaireAuthor">{comment.Prenom} {comment.Nom}</p>
                <div className="commentaire-rate rate">
                  <input type="radio" id="star5" name="rate" value="5" />
                  <label htmlFor="star5" title="text">
                    5 stars
                  </label>
                  <input type="radio" id="star4" name="rate" value="4" />
                  <label htmlFor="star4" title="text">
                    4 stars
                  </label>
                  <input type="radio" id="star3" name="rate" value="3" />
                  <label htmlFor="star3" title="text">
                    3 stars
                  </label>
                  <input type="radio" id="star2" name="rate" value="2" />
                  <label htmlFor="star2" title="text">
                    2 stars
                  </label>
                  <input type="radio" id="star1" name="rate" value="1" />
                  <label htmlFor="star1" title="text">
                    1 star
                  </label>
                </div>
              </div>
            </div>
            <p className="CommentaireP">{comment.contenu}</p>
          </div>
        ))}
        <div className="carousel-navigation2">
          <button
            className={`carousel-prev-button2 ${currentSlide === 0 ? 'disabled2' : ''}`}
            onClick={handlePrevSlide}
            disabled={currentSlide === 0}
          >
            <FontAwesomeIcon icon={faCommentDots} className="carousel-comment-icon" />
          </button>
          <div className="carousel-page-number">
            {currentSlide + 1} / {totalSlides}
          </div>
          <button
            className={`carousel-next-button2 ${currentSlide === totalSlides - 1 ? 'disabled2' : ''}`}
            onClick={handleNextSlide}
            disabled={currentSlide === totalSlides - 1}
          >
            <FontAwesomeIcon icon={faCommentDots} className="carousel-comment-icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentComponent;